import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import { Table } from "antd";
import "antd/dist/antd.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";

import { css } from "@emotion/react";

import ScaleLoader from "react-spinners/ScaleLoader";
import imgData from "../../logo.png";
const override = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 80%;
  left: 50%;
`;

const TablePanel = (props) => {
  const [filteredInfo, setFilteredInfo] = useState(null);

  useEffect(() => {}, [props.isLoading]);

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const tableData = props.data || [];

  let filterData = [];

  for (let i = 0; i < tableData.length; i++) {
    filterData.push(tableData[i].name);
  }

  filterData = filterData.filter((v, i, a) => a.indexOf(v) === i);

  const uniqueFilterData = [];

  for (let i = 0; i < filterData.length; i++) {
    uniqueFilterData.push({
      text: filterData[i],
      value: filterData[i],
    });
  }

  const columns = [
    {
      title: "Location Name",
      dataIndex: "name",
      key: "name",
      filters: uniqueFilterData,
      onFilter: (value, record) => record.name.includes(value),
    },
    {
      title: "Tank Number",
      dataIndex: "tank_no",
      key: "tank_no",
      align: "center",
    },
    {
      title: "Fluid Brand",
      dataIndex: "fluid_brand",
      key: "fluid_brand",
      align: "center",
    },
    {
      title: "Fluid Segment",
      dataIndex: "fluid_segment",
      key: "fluid_segment",
      align: "center",
    },
    {
      title: "Product",
      dataIndex: "fluid_product",
      key: "fluid_product",
      align: "center",
    },
    {
      title: "Tank Capacity",
      dataIndex: "capacity_gallons",
      key: "capacity_gallons",
      align: "center",
    },
    {
      title: "Current Gallons",
      dataIndex: "calc_gal",
      key: "calc_gal",
      align: "center",
    },
    {
      title: "Current Inches",
      dataIndex: "calc_inch",
      key: "calc_inch",
      align: "center",
    },
  ];

  const data = tableData.map((d) => ({
    ...d,
  }));

  const toPrintPdfData = data;

  const columsTOfilter = columns.map((eachCol) => Object(eachCol).dataIndex);

  const pdfJsonData = [];
  console.log(props.isLoading);

  for (let eachObj of toPrintPdfData) {
    var tempObj = {};
    for (let eachKey of Object.keys(eachObj)) {
      if (columsTOfilter.includes(eachKey)) {
        tempObj[eachKey] = eachObj[eachKey];
      }
    }
    pdfJsonData.push(tempObj);
  }

  const getTimeStamp = () => {
    var m_names = new Array(
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    );

    var today = new Date();
    var curr_date = today.getDate();
    var curr_month = today.getMonth();
    var curr_year = today.getFullYear();
    var curr_hour =
      today.getHours() > 12 ? today.getHours() - 12 : today.getHours();

    var curr_min = today.getMinutes();
    var curr_secs = today.getSeconds();
    var curr_ampm = today.getHours() > 12 ? "PM" : "AM";

    today =
      m_names[curr_month] +
      " " +
      curr_date +
      ", " +
      curr_year +
      " " +
      curr_hour +
      ":" +
      curr_min +
      ":" +
      curr_secs +
      " " +
      curr_ampm;
    var newdat = today;

    return newdat;
  };

  const generatePDF = () => {
    const columns = [
      {
        title: "Location Name",
        dataKey: "name",
      },
      {
        title: "Tank Number",
        dataKey: "tank_no",
      },
      {
        title: "Fluid Brand",
        dataKey: "fluid_brand",
      },

      {
        title: "Product",
        dataKey: "fluid_product",
      },
      {
        title: "Fluid Segment",
        dataKey: "fluid_segment",
      },
      {
        title: "Current Gallons",
        dataKey: "calc_gal",
      },

      {
        title: "Tank Capacity",
        dataKey: "capacity_gallons",
      },
      {
        title: "Current Inches",
        dataKey: "calc_inch",
      },
    ];

    const doc = new jsPDF({
      orientation: "portrait",
      unit: "in",
      format: "letter",
    });

    // date
    const timeStamp = getTimeStamp();
    doc.setFontSize(16).text("Tank Inventory Report", 0.5, 1.0);
    doc.setFontSize(8).text(timeStamp, 6.5, 1.0);
    doc.setDrawColor(0, 96, 168);

    doc.setLineWidth(0.01).line(0.5, 1.1, 8.0, 1.1);
    doc.addImage(imgData, "PNG", 3.5, 0, 2.0, 0.75);

    doc.autoTable({
      columns,
      body: pdfJsonData,
      margin: { left: 0.5, top: 1.25 },
      theme: "striped",
      bodyStyles: {
        margin: 40,
        fontSize: 10,
        lineWidth: 0,
        lineColor: [0, 0, 0],
      },
      columnStyles: [
        { halign: "left" },
        { halign: "right" },
        { halign: "left" },
        { halign: "left" },
        { halign: "left" },
        { halign: "right" },
        { halign: "right" },
        { halign: "right" },
      ],
    });

    doc.save(`Tank Inventory Report ${timeStamp}.pdf`);
  };

  const exportToXlsx = () => {
    const timeStamp = getTimeStamp();
    const fileName = `Tank Inventory Report ${timeStamp}.xlsx`;

    const ws = XLSX.utils.json_to_sheet(pdfJsonData, {
      header: [
        "name",
        "tank_no",
        "fluid_brand",
        "fluid_product",
        "fluid_segment",
        "calc_gal",
        "capacity_gallons",
        "calc_inch",
      ],
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Tank Inventory");

    XLSX.writeFile(wb, fileName);
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        onChange={() => handleChange}
        loading={{
          indicator: (
            <ScaleLoader
              color={"#0060A8"}
              loading={props.isLoading}
              css={override}
              size={50}
            />
          ),
          spinning: props.isLoading,
        }}
        title={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h1>Tank Inventory</h1>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  marginBottom: "0",
                  marginRight: "1.5rem",
                  fontStyle: "italic",
                }}
              >
                Download As :
              </p>

              <FontAwesomeIcon
                onClick={generatePDF}
                icon={faFilePdf}
                style={{
                  color: "#0060a8",
                  fontSize: "2rem",
                  cursor: "pointer",

                  marginRight: "10px",
                }}
                title="Download As PDF"
              />
              <FontAwesomeIcon
                onClick={exportToXlsx}
                icon={faFileExcel}
                style={{
                  color: "#0060a8",
                  fontSize: "2rem",
                  cursor: "pointer",

                  marginRight: "10px",
                }}
                title="Download As XSLX"
              />
            </div>
          </div>
        )}
      />
    </>
  );
};

const styles = () => ({
  root: { width: "100%", overflowX: "auto" },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  head: { fontWeight: 900 },
});

export default withStyles(styles)(TablePanel);

const inventoryTablesStyle = {
  justifyContent: "space-between",
};