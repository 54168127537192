import React, { useEffect, useState } from 'react'
import {
	Button,
	Dialog,
	Modal,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Typography,
	TextField,
} from '@material-ui/core'
import { withDataProvider, showNotification } from 'react-admin'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { decodeToken } from '../utils/helper'
import history from '../utils/history'

function ResetPassword(props) {
	const [newPassword, setnewPassword] = useState(null)
	const [confirmPassword, setconfirmPassword] = useState(null)
	const [checkModal, setcheckModal] = useState(true)
	const [isTokenValid, setisTokenValid] = useState(null)
	const [time, setTime] = useState(Date.now())

	const onChange = (e) => {
		if (e.target.name === 'new_password') {
			setnewPassword(e.target.value)
		}
		if (e.target.name === 'confirm_password') {
			setconfirmPassword(e.target.value)
		}
	}

	const onSubmit = async (e) => {
		e.preventDefault()
		const { dataProvider, showNotification } = props
		if (newPassword !== confirmPassword) {
			showNotification('Mismatch Password', 'error')
		} else {
			const { location } = props
			const token = location.search.slice(7, location.search.length - 1)
			const decode = decodeToken(token)
			await dataProvider.update('users', {
				data: { new_password: newPassword },
				id: decode.data.id,
				type: 'reset-password',
			})
			showNotification('Password has been successfully changed', 'info', {
				autoHideDuration: 3000,
			})
			history.push('/login')
		}
	}

	useEffect(() => {
		const { location } = props
		const token = location.search.slice(7, location.search.length - 1)
		const decode = decodeToken(token)

		if (decode.exp <= Date.now() / 1000) {
			showNotification('Link Expired', 'warning', {
				autoHideDuration: 3000,
			})
			setisTokenValid(false)
		} else {
			setisTokenValid(true)
		}

		const interval = setInterval(() => setTime(Date.now()), 60000)

		return () => {
			clearInterval(interval)
		}
	}, [time])

	return (
		<div>
			{isTokenValid ? (
				<Modal open={checkModal}>
					<Dialog
						open
						// onClose={handleClose}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'
						maxWidth='xs'
						fullWidth>
						<DialogTitle id='alert-dialog-title'>Reset Password</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-description'>
								<TextField
									name='new_password'
									type='password'
									label='New Password'
									onChange={onChange}
									fullWidth
									required
									margin='normal'
									// className={classes.textField}
									// error={error}
								/>
								<TextField
									name='confirm_password'
									type='password'
									label='Confirm Password'
									required
									margin='normal'
									onChange={onChange}
									fullWidth
									// className={classes.textField}
									// error={error}
								/>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button>
								<Typography color='primary' onClick={onSubmit}>
									Submit
								</Typography>
							</Button>
						</DialogActions>
					</Dialog>
				</Modal>
			) : (
				<Modal open={true}>
					<Dialog
						open
						// onClose={handleClose}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'
						maxWidth='xs'
						fullWidth>
						<DialogTitle id='alert-dialog-title'>Session Expired</DialogTitle>
						<DialogContent></DialogContent>
						<DialogActions>
							<Button>
								<Typography
									color='primary'
									onClick={() => history.push('/login')}>
									Go to Login Page
								</Typography>
							</Button>
						</DialogActions>
					</Dialog>
				</Modal>
			)}
		</div>
	)
}

ResetPassword.propTypes = {
	dataProvider: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	location: state.router.location,
})

const withConnect = connect(mapStateToProps, { showNotification })

export default compose(withDataProvider)(withConnect(ResetPassword))
