import moment from 'moment-timezone';

const timestampFormat = (timestamp) => {
  const preferences = JSON.parse(localStorage.getItem('preferences'));
  let timeformat = '';

  switch (preferences.timeFormat) {
    case '24 Hour':
      timeformat = 'HH:mm';
      break;
    case '12 Hour':
      timeformat = 'hh:mm A';
      break;
    default:
      timeformat = 'hh:mm A';
  }

  const xDateFormat = `${preferences.dateFormat} ${timeformat}`;

  return moment(timestamp).format(xDateFormat);
};

export default timestampFormat;
