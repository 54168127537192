import React, { useCallback } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import dataProvider from "./dataProvider";
import { AuthContext, TimezoneContext } from "./contexts";
import AdminPage from "./AdminPage";
import * as actions from "./store/actions";

const localTimezone = moment.tz.guess(true);

const App = (props) => {
  const token = localStorage.getItem("token");
  const { setDistributorData, setLocationData, setTankData } = props;

  const [timezone, setTimezone] = React.useState(localTimezone);
  const [authenticatedData, setAuthenticatedData] = React.useState({
    authenticated: token !== null,
    setAuthenticated: (authenticated) => {
      setAuthenticatedData(authenticated);
    },
  });

  const setupDistributorData = useCallback(
    (distributors) => {
      const selectedDistributor =
        distributors.length > 0 ? distributors[0] : undefined;
      setDistributorData({ distributors, selectedDistributor });
    },
    [setDistributorData]
  );

  const setupLocationData = useCallback(
    (locations) => {
      const selectedLocation = locations.length > 0 ? locations[0] : undefined;
      setLocationData({
        locations,
        selectedLocation: {
          id: selectedLocation.id,
          desc: `${selectedLocation.name}, ${selectedLocation.address.name}`,
          timezone: selectedLocation.timezone,
        },
      });
    },
    [setLocationData]
  );

  const setupTankData = useCallback(
    (locations, tanks) => {
      let selectedTank;
      const selectedLocation = locations.length > 0 ? locations[0] : undefined;
      if (selectedLocation && tanks.length > 0) {
        const filteredTanks = tanks.filter(
          (tank) => tank.location_id === selectedLocation.id
        );
        selectedTank = filteredTanks.length > 0 ? filteredTanks[0] : undefined;
      }
      setTankData({ tanks, selectedTank });
    },
    [setTankData]
  );

  React.useEffect(() => {
    async function fetchData() {
      try {
        const [distributors, locations] = await Promise.all([
          dataProvider
            .getAll("distributors", { filter: "" })
            .then(({ data }) => data),
          dataProvider
            .getAll("locations", { filter: "" })
            .then(({ data }) => data),
        ]);
        distributors.sort((a, b) => a.name.localeCompare(b.name));
        locations.sort((a, b) => a.name.localeCompare(b.name));
        const tanks = await dataProvider
          .getAll("tanks", { filter: { tank_status: "Yes" } })
          .then(({ data }) => data);
        tanks.sort((a, b) => a.fluid_product.localeCompare(b.fluid_product));

        setupDistributorData(distributors);
        setupLocationData(locations);
        setupTankData(locations, tanks);
      } catch (err) {
        console.log(err);
      }
    }

    if (authenticatedData.authenticated) {
      fetchData();
    }
  }, [
    authenticatedData,
    setAuthenticatedData,
    token,
    setupDistributorData,
    setupLocationData,
    setupTankData,
  ]);

  return (
    <AuthContext.Provider value={authenticatedData}>
      <TimezoneContext.Provider
        value={{
          timezone,
          setTimezone: (tz) => {
            // moment.tz.setDefault(tz);
            setTimezone(tz);
          },
        }}
      >
        <AdminPage />
      </TimezoneContext.Provider>
    </AuthContext.Provider>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setDistributorData: (data) =>
    dispatch({ type: actions.SET_DISTRIBUTOR_DATA, value: data }),
  setLocationData: (data) =>
    dispatch({ type: actions.SET_LOCATION_DATA, value: data }),
  setTankData: (data) => dispatch({ type: actions.SET_TANK_DATA, value: data }),
});

const withConnect = connect(null, mapDispatchToProps);

export default withConnect(App);
