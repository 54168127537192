import React from 'react';
import {
  Edit, SimpleForm, TextInput, required, minLength,
} from 'react-admin';

const redirect = () => '/references';

const validateName = [required(), minLength(2)];

export default ({ permissions, ...props }) => (
  <Edit title="Edit Reference" {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput disabled source="id" />
      <TextInput source="ref_category" validate={validateName} />
      <TextInput source="ref_value" validate={validateName} />
    </SimpleForm>
  </Edit>
);
