import RuleIcon from '@material-ui/icons/EventNote';
import RuleList from './RuleList';
import RuleEdit from './RuleEdit';
import RuleCreate from './RuleCreate';

export default {
  list: RuleList,
  edit: RuleEdit,
  create: RuleCreate,
  icon: RuleIcon,
};
