import React from 'react';
import {
  Create,
  TextInput,
  BooleanInput,
  required,
  minValue,
  maxValue,
  number,
  SimpleForm,
  RadioButtonGroupInput,
} from 'react-admin';

const redirect = () => '/rules';

const validateRequired = required();
const validateMin = minValue(0);
const validateMax = maxValue(100);
const validateNumber = number();

export default props => (
  <Create {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="rule_name" validate={[validateRequired]} />
      <RadioButtonGroupInput
        source="operator"
        choices={[
          { id: '<=', name: '<=' },
          { id: '>=', name: '>=' },
          { id: '<', name: '<' },
          { id: '>', name: '>' },
        ]}
        validate={[validateRequired]}
      />
      <TextInput
        source="threshold"
        type="number"
        validate={[validateMin, validateMax, validateRequired, validateNumber]}
      />
      <BooleanInput source="is_active" defaultValue />
    </SimpleForm>
  </Create>
);
