import ManufacturerIcon from '@material-ui/icons/Domain';
import ManufacturerList from './ManufacturerList';
import ManufacturerEdit from './ManufacturerEdit';
import ManufacturerCreate from './ManufacturerCreate';

export default {
  list: ManufacturerList,
  edit: ManufacturerEdit,
  create: ManufacturerCreate,
  icon: ManufacturerIcon,
};
