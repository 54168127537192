import React from 'react'
import {
	Edit,
	TabbedForm,
	FormTab,
	ReferenceInput,
	SelectInput,
	TextInput,
	DateTimeInput,
	BooleanInput,
	SelectArrayInput,
	CheckboxGroupInput,
	ReferenceArrayInput,
	required,
	minLength,
	email,
} from 'react-admin'

const redirect = () => '/users'

const validateEmail = [required(), email()]
const validateName = [required(), minLength(2)]
const validateRequired = required()

export default ({ permissions, ...props }) => {
	const onSuccess = ({ data }) => {
		console.log('data', data.preferences)
		console.log('data', localStorage.getItem('preferences'))
		localStorage.setItem(
			'preferences',
			JSON.stringify({
				...data.preferences,
				length: data.preferences.lengthRefId === 34 ? 'Centimeters' : 'Inches',
				dateFormat: data.preferences.dateRefId === 38 ? 'MM-DD-YY' : 'DD-MM-YY',
				timeFormat: data.preferences.timeRefId === 40 ? '24 Hour' : '12 Hour',
				volume:
					data.preferences.volumeRefId === 32
						? 'Gallons'
						: data.preferences.volumeRefId === 33
						? 'Litres'
						: 'Quarts',
			})
		)
	}

	return (
		<Edit
			mutationMode='pessimistic'
			onSuccess={onSuccess}
			title='Edit User'
			{...props}>
			<TabbedForm redirect={redirect}>
				<FormTab label='Summary'>
					<TextInput disabled source='id' />
					<TextInput disabled source='email' validate={validateEmail} />
					<TextInput source='name' validate={validateName} />
					<BooleanInput source='is_active' />
					<DateTimeInput source='created_at' disabled />
					<DateTimeInput source='updated_at' disabled />
				</FormTab>
				<FormTab label='Access Control'>
					<ReferenceInput
						label='Role'
						source='role_ref_id'
						reference='references'
						validate={[required()]}
						sort={{ field: 'ref_category', order: 'ASC' }}
						filter={{ ref_category: 'ROLE' }}>
						<SelectInput optionText='ref_value' />
					</ReferenceInput>
					<CheckboxGroupInput
						label='Dashboard Access'
						source='dashboard_access'
						choices={[
							{ id: 'distributor', name: 'Distributor' },
							{ id: 'location', name: 'Location' },
							{ id: 'tank', name: 'Tank' },
						]}
					/>
					<ReferenceArrayInput
						label='Retailers'
						source='retailers'
						reference='retailers'
						style={{ width: '500px' }}>
						<SelectArrayInput optionText='name' />
					</ReferenceArrayInput>
					<ReferenceArrayInput
						label='Manufacturers'
						source='manufacturers'
						reference='manufacturers'
						style={{ width: '500px' }}>
						<SelectArrayInput optionText='name' />
					</ReferenceArrayInput>
					<ReferenceArrayInput
						label='Distributors'
						source='distributors'
						reference='distributors'
						style={{ width: '500px' }}>
						<SelectArrayInput optionText='name' />
					</ReferenceArrayInput>
					<ReferenceArrayInput
						label='Locations'
						source='locations'
						reference='locations'
						style={{ width: '500px' }}>
						<SelectArrayInput optionText='name' />
					</ReferenceArrayInput>
				</FormTab>
				<FormTab label='Preferences'>
					<ReferenceInput
						source='volume_ref_id'
						label='Volume unit'
						reference='references'
						sort={{ field: 'ref_value', order: 'ASC' }}
						filter={{ ref_category: 'VOLUME_UNIT' }}
						validate={validateRequired}>
						<SelectInput optionText='ref_value' />
					</ReferenceInput>
					<ReferenceInput
						source='length_ref_id'
						label='Length unit'
						reference='references'
						sort={{ field: 'ref_value', order: 'ASC' }}
						filter={{ ref_category: 'LENGTH_UNIT' }}
						validate={validateRequired}>
						<SelectInput optionText='ref_value' />
					</ReferenceInput>
					<ReferenceInput
						source='date_ref_id'
						label='Date format'
						reference='references'
						sort={{ field: 'ref_value', order: 'ASC' }}
						filter={{ ref_category: 'DATE_FORMAT' }}
						validate={validateRequired}>
						<SelectInput optionText='ref_value' />
					</ReferenceInput>
					<ReferenceInput
						source='time_ref_id'
						label='Time format'
						reference='references'
						sort={{ field: 'ref_value', order: 'ASC' }}
						filter={{ ref_category: 'TIME_FORMAT' }}
						validate={validateRequired}>
						<SelectInput optionText='ref_value' />
					</ReferenceInput>
				</FormTab>
			</TabbedForm>
		</Edit>
	)
}
