import DistributorIcon from '@material-ui/icons/LocalShipping';
import DistributorList from './DistributorList';
import DistributorEdit from './DistributorEdit';
import DistributorCreate from './DistributorCreate';

export default {
  list: DistributorList,
  edit: DistributorEdit,
  create: DistributorCreate,
  icon: DistributorIcon,
};
