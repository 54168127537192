import React, { useState } from "react";
import {
  Create,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  DateInput,
  NumberInput,
  TabbedForm,
  ReferenceArrayInput,
  DateTimeInput,
  SelectArrayInput,
  FormTab,
  required,
  minValue,
  number,
  minLength,
  maxLength,
  FormDataConsumer,
} from "react-admin";

const redirect = () => "/tanks";

const validateRequired = required();
const validateInteger = minValue(0);
const validateNumber = number();
const validateMinLength = minLength(6, "Must be 6 characters long");
const validateMaxLength = maxLength(6, "Must be 6 characters long");

const ruleOptionRenderer = (rule) =>
  `${rule.rule_name}: ${rule.operator} ${rule.threshold}`;

const TankCreate = (props) => {
  return (
    <Create {...props}>
      <TabbedForm redirect={redirect}>
        <FormTab label="Summary">
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.tank_status === "Pending" && (
                <>
                  <NumberInput
                    style={{ width: 256 }}
                    label="Tank position no"
                    source="tank_no"
                  />
                  <br />
                  <ReferenceInput
                    style={{ width: 256 }}
                    source="retailer_id"
                    reference="retailers"
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                  <br />
                  <ReferenceInput
                    style={{ width: 256 }}
                    source="distributor_id"
                    reference="distributors"
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                  <br />
                  <ReferenceInput
                    style={{ width: 256 }}
                    source="location_id"
                    reference="locations"
                    validate={[validateRequired]}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              (formData.tank_status === "Yes" ||
                formData.tank_status === "No") && (
                <>
                  <NumberInput
                    style={{ width: 256 }}
                    label="Tank position no"
                    source="tank_no"
                    validate={[
                      validateRequired,
                      validateNumber,
                      validateInteger,
                    ]}
                  />
                  <br />
                  <ReferenceInput
                    style={{ width: 256 }}
                    source="retailer_id"
                    reference="retailers"
                    validate={[validateRequired]}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                  <br />
                  <ReferenceInput
                    style={{ width: 256 }}
                    source="distributor_id"
                    reference="distributors"
                    validate={[validateRequired]}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                  <br />
                  <ReferenceInput
                    style={{ width: 256 }}
                    source="location_id"
                    reference="locations"
                    validate={[validateRequired]}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </>
              )
            }
          </FormDataConsumer>
          <SelectInput
            source="tank_status"
            choices={[
              { id: "Yes", name: "Active" },
              { id: "No", name: "Inactive" },
              { id: "Pending", name: "Pending" },
            ]}
            defaultValue={"Yes"}
          />
          {/* <BooleanInput source="is_active" defaultValue /> */}
        </FormTab>
        <FormTab label="Fluid Details">
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.tank_status === "Pending" && (
                <>
                  <ReferenceInput
                    label="Fluid type"
                    source="fluid_type_ref_id"
                    reference="references"
                    sort={{ field: "ref_value", order: "ASC" }}
                    filter={{ ref_category: "FLUID_TYPE" }}
                    style={{ width: 256 }}
                    validate={[validateRequired]}
                  >
                    <SelectInput optionText="ref_value" />
                  </ReferenceInput>
                  <br />
                  <ReferenceInput
                    label="Fluid state"
                    source="fluid_state_ref_id"
                    reference="references"
                    sort={{ field: "ref_value", order: "ASC" }}
                    filter={{ ref_category: "FLUID_STATE" }}
                    style={{ width: 256 }}
                    validate={[validateRequired]}
                  >
                    <SelectInput optionText="ref_value" />
                  </ReferenceInput>
                  <br />
                  <ReferenceInput
                    label="Fluid manufacturer"
                    source="manufacturer_id"
                    reference="manufacturers"
                    validate={[validateRequired]}
                    style={{ width: 256 }}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                  <br />
                  <TextInput
                    source="fluid_brand"
                    validate={[validateRequired]}
                    style={{ width: 256 }}
                  />
                  <br />
                  <ReferenceInput
                    label="Fluid segment"
                    source="fluid_segment_ref_id"
                    reference="references"
                    sort={{ field: "ref_value", order: "ASC" }}
                    filter={{ ref_category: "FLUID_SEGMENT" }}
                    validate={[validateRequired]}
                    style={{ width: 256 }}
                  >
                    <SelectInput optionText="ref_value" />
                  </ReferenceInput>
                  <br />
                  <ReferenceInput
                    label="Fluid product"
                    source="fluid_product_type_ref_id"
                    perPage={50}
                    reference="references"
                    sort={{ field: "ref_value", order: "ASC" }}
                    filter={{ ref_category: "FLUID_PRODUCT_TYPE" }}
                    style={{ width: 256 }}
                    validate={[validateRequired]}
                  >
                    <SelectInput optionText="ref_value" />
                  </ReferenceInput>
                </>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              (formData.tank_status === "Yes" ||
                formData.tank_status === "No") && (
                <>
                  <ReferenceInput
                    label="Fluid type"
                    source="fluid_type_ref_id"
                    reference="references"
                    sort={{ field: "ref_value", order: "ASC" }}
                    filter={{ ref_category: "FLUID_TYPE" }}
                    validate={[validateRequired]}
                    style={{ width: 256 }}
                  >
                    <SelectInput optionText="ref_value" />
                  </ReferenceInput>
                  <br />
                  <ReferenceInput
                    label="Fluid state"
                    source="fluid_state_ref_id"
                    reference="references"
                    sort={{ field: "ref_value", order: "ASC" }}
                    filter={{ ref_category: "FLUID_STATE" }}
                    validate={[validateRequired]}
                    style={{ width: 256 }}
                  >
                    <SelectInput optionText="ref_value" />
                  </ReferenceInput>
                  <br />
                  <ReferenceInput
                    label="Fluid manufacturer"
                    source="manufacturer_id"
                    reference="manufacturers"
                    validate={[validateRequired]}
                    style={{ width: 256 }}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                  <br />
                  <TextInput
                    source="fluid_brand"
                    validate={[validateRequired]}
                    style={{ width: 256 }}
                  />
                  <br />
                  <ReferenceInput
                    label="Fluid segment"
                    source="fluid_segment_ref_id"
                    reference="references"
                    sort={{ field: "ref_value", order: "ASC" }}
                    filter={{ ref_category: "FLUID_SEGMENT" }}
                    validate={[validateRequired]}
                    style={{ width: 256 }}
                  >
                    <SelectInput optionText="ref_value" />
                  </ReferenceInput>
                  <br />
                  <ReferenceInput
                    label="Fluid product"
                    source="fluid_product_type_ref_id"
                    perPage={50}
                    reference="references"
                    sort={{ field: "ref_value", order: "ASC" }}
                    filter={{ ref_category: "FLUID_PRODUCT_TYPE" }}
                    validate={[validateRequired]}
                    style={{ width: 256 }}
                  >
                    <SelectInput optionText="ref_value" />
                  </ReferenceInput>
                </>
              )
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Measurements">
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.tank_status === "Pending" && (
                <>
                  <ReferenceInput
                    source="tank_manufacturer_id"
                    reference="tank-manufacturers"
                    style={{ width: 256 }}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="capacity_gallons"
                  />
                  <br />
                  <NumberInput style={{ width: 256 }} source="depth_inches" />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="inner_depth_inches"
                  />
                  <br />
                  <NumberInput style={{ width: 256 }} source="width_inches" />
                  <br />
                  <NumberInput style={{ width: 256 }} source="length_inches" />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="sensor_length_inches"
                  />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    label="Sensing length inches"
                    source="sensor_depth_inches"
                  />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="lowest_min_percent"
                  />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="adjustment_factor"
                  />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="curr_calibration"
                    defaultValue={1}
                    label="Current Calibration"
                  />
                </>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              (formData.tank_status === "Yes" ||
                formData.tank_status === "No") && (
                <>
                  <ReferenceInput
                    source="tank_manufacturer_id"
                    reference="tank-manufacturers"
                    validate={[validateRequired]}
                    style={{ width: 256 }}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="capacity_gallons"
                    validate={[
                      validateRequired,
                      validateNumber,
                      validateInteger,
                    ]}
                  />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="depth_inches"
                    validate={[validateRequired, validateNumber]}
                  />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="inner_depth_inches"
                    validate={[validateNumber]}
                  />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="width_inches"
                    validate={[validateNumber]}
                  />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="length_inches"
                    validate={[validateNumber]}
                  />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="sensor_length_inches"
                    validate={[validateNumber]}
                  />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    label="Sensing length inches"
                    source="sensor_depth_inches"
                    validate={[validateNumber]}
                  />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="lowest_min_percent"
                    validate={[validateNumber]}
                  />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="adjustment_factor"
                    validate={[validateNumber]}
                  />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="curr_calibration"
                    defaultValue={1}
                    label="Current Calibration"
                  />
                </>
              )
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Installation Details">
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.tank_status === "Pending" && (
                <>
                  <DateInput
                    source="install_date"
                    validate={[validateRequired]}
                    style={{ width: 256 }}
                  />
                  <br />
                  <TextInput source="installer_name" style={{ width: 256 }} />
                  <br />
                  <TextInput
                    source="installer_contact"
                    style={{ width: 256 }}
                  />
                </>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              (formData.tank_status === "Yes" ||
                formData.tank_status === "No") && (
                <>
                  <DateInput
                    source="install_date"
                    validate={[validateRequired]}
                    style={{ width: 256 }}
                  />
                  <br />
                  <TextInput source="installer_name" style={{ width: 256 }} />
                  <br />
                  <TextInput
                    source="installer_contact"
                    style={{ width: 256 }}
                  />
                </>
              )
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Sensor Details">
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.tank_status === "Pending" && (
                <>
                  <TextInput
                    style={{ width: 256 }}
                    source="sensor_supplier_name"
                  />
                  <br />
                  <NumberInput style={{ width: 256 }} source="node" />
                  <br />
                  <NumberInput style={{ width: 256 }} source="port" />
                  <br />
                  <ReferenceInput
                    style={{ width: 256 }}
                    source="sensor_tech_type_ref_id"
                    label="Sensor Tech Type"
                    reference="references"
                    sort={{ field: "ref_value", order: "ASC" }}
                    filter={{ ref_category: "SENSOR_TECH" }}
                  >
                    <SelectInput optionText="ref_value" />
                  </ReferenceInput>
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="sensor_warranty_years"
                    type="number"
                  />
                  <br />
                  <TextInput
                    source="sensor_supplier_contact"
                    style={{ width: 256 }}
                  />

                  <br />
                  <DateInput
                    source="warranty_expiration_date"
                    style={{ width: 256 }}
                  />
                  <br />
                  <TextInput style={{ width: 256 }} source="stream_id" />
                  <br />
                  <TextInput style={{ width: 256 }} source="serial_no" />
                  <br />
                  <ReferenceInput
                    style={{ width: 256 }}
                    source="sensor_type_ref_id"
                    label="Sensor Type *"
                    reference="references"
                    sort={{ field: "ref_value", order: "ASC" }}
                    filter={{ ref_category: "SENSOR_TYPE" }}
                    validate={[validateRequired]}
                  >
                    <SelectInput optionText="ref_value" />
                  </ReferenceInput>
                </>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              (formData.tank_status === "Yes" ||
                formData.tank_status === "No") && (
                <>
                  <TextInput
                    style={{ width: 256 }}
                    source="sensor_supplier_name"
                  />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="node"
                    validate={[
                      validateRequired,
                      validateNumber,
                      validateInteger,
                    ]}
                  />
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="port"
                    validate={[
                      validateRequired,
                      validateNumber,
                      validateInteger,
                    ]}
                  />
                  <br />
                  <ReferenceInput
                    style={{ width: 256 }}
                    source="sensor_tech_type_ref_id"
                    label="Sensor Tech Type"
                    reference="references"
                    sort={{ field: "ref_value", order: "ASC" }}
                    filter={{ ref_category: "SENSOR_TECH" }}
                  >
                    <SelectInput optionText="ref_value" />
                  </ReferenceInput>
                  <br />
                  <NumberInput
                    style={{ width: 256 }}
                    source="sensor_warranty_years"
                    type="number"
                    validate={[validateNumber, validateInteger]}
                  />
                  <br />
                  <TextInput
                    source="sensor_supplier_contact"
                    style={{ width: 256 }}
                  />

                  <br />
                  <DateInput
                    source="warranty_expiration_date"
                    style={{ width: 256 }}
                  />
                  <br />
                  <TextInput
                    style={{ width: 256 }}
                    source="stream_id"
                    validate={[validateRequired]}
                  />
                  <br />
                  <TextInput
                    style={{ width: 256 }}
                    source="serial_no"
                    validate={[
                      validateRequired,
                      validateMinLength,
                      validateMaxLength,
                    ]}
                  />
                  <br />
                  <ReferenceInput
                    style={{ width: 256 }}
                    source="sensor_type_ref_id"
                    label="Sensor Type *"
                    reference="references"
                    sort={{ field: "ref_value", order: "ASC" }}
                    filter={{ ref_category: "SENSOR_TYPE" }}
                    validate={[validateRequired]}
                  >
                    <SelectInput optionText="ref_value" />
                  </ReferenceInput>
                </>
              )
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Rules">
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.tank_status === "Pending" && (
                <>
                  <ReferenceInput
                    style={{ width: 256 }}
                    source="rule_id"
                    reference="rules"
                    validate={[validateRequired]}
                  >
                    <SelectInput optionText={ruleOptionRenderer} />
                  </ReferenceInput>
                  <br />
                  <ReferenceArrayInput
                    style={{ width: 256 }}
                    label="Send notifications to"
                    source="user_ids"
                    reference="users"
                  >
                    <SelectArrayInput optionText="email" />
                  </ReferenceArrayInput>
                </>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              (formData.tank_status === "Yes" ||
                formData.tank_status === "No") && (
                <>
                  <ReferenceInput
                    style={{ width: 256 }}
                    source="rule_id"
                    reference="rules"
                  >
                    <SelectInput optionText={ruleOptionRenderer} />
                  </ReferenceInput>
                  <br />
                  <ReferenceArrayInput
                    style={{ width: 256 }}
                    label="Send notifications to"
                    source="user_ids"
                    reference="users"
                  >
                    <SelectArrayInput optionText="email" />
                  </ReferenceArrayInput>
                </>
              )
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Tank Simulation">
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.tank_status === "Pending" && (
                <>
                  <BooleanInput
                    style={{ width: 256 }}
                    source="is_simulated"
                    defaultValue={false}
                  />
                  <br />
                  <DateTimeInput
                    style={{ width: 256 }}
                    source="simulation_start_date"
                  />
                  <br />
                  <NumberInput style={{ width: 256 }} source="empty_in_days" />
                </>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              (formData.tank_status === "Yes" ||
                formData.tank_status === "No") && (
                <>
                  <BooleanInput
                    style={{ width: 256 }}
                    source="is_simulated"
                    defaultValue={false}
                  />
                  <br />
                  <DateTimeInput
                    style={{ width: 256 }}
                    source="simulation_start_date"
                  />
                  <br />
                  <NumberInput style={{ width: 256 }} source="empty_in_days" />
                </>
              )
            }
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default TankCreate;
