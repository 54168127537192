import React from "react";
import { CSVLink } from "react-csv";
import { Button, withWidth, withStyles } from "@material-ui/core";
import FileDownload from "@material-ui/icons/CloudDownload";
import { compose } from "recompose";
import unitMap from "../utils/unitMap";
import comparator from "../utils/comparator";
import { useMediaQuery } from "@material-ui/core";

const DownloadLocationCsv = (props) => {
  const { filename, lastData, width, classes, timeRangeLabel } = props;

  const preferences = JSON.parse(localStorage.getItem("preferences"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const data = lastData.map((e) => {
    const actionPlan = [];
    if (comparator(e.percent, e.threshold, e.operator)) {
      actionPlan.push({
        text: e.fluidState === "New" ? "Low Level: " : "High Level: ",
        value: e.rule_name,
      });
      actionPlan.push({ text: "Call Distributor ", value: "" });
      if (e.empty) {
        actionPlan.push({
          text:
            e.fluidState === "New"
              ? "Estimated Outage Days: "
              : "Estimated Days Until Full: ",
          value: `${Math.round(e.empty)} days`,
        });
      }
    }

    let actionPlanTextArray;
    if (actionPlan.length > 0) {
      actionPlanTextArray = actionPlan.map((a) => `${a.text}${a.value || ""}`);
    }
    const avgKey =
      e.avg !== undefined && e.days !== undefined
        ? `Avg. daily usage (${preferences.volume} - ${timeRangeLabel})`
        : "";
    const emptyCol = `${e.fluidState === "New" ? "Empty" : "Full"} In Days`;
    return {
      Tank: e.desc,
      "Action Plan": (actionPlanTextArray || []).join("\n") || "No Action Plan",
      Percent: e.percent,
      [avgKey]: (e.avg * unitMap[preferences.volume]).toFixed(2),
      [emptyCol]: Math.round(e.empty),
      [`Volume Needed (${preferences.volume})`]: Math.round(
        (e.capacityGallons - e.calcGal) * unitMap[preferences.volume]
      ),
      [`Current Volume (${preferences.volume})`]: Math.round(
        e.calcGal * unitMap[preferences.volume]
      ),
      [`Current Volume (${preferences.length})`]: (
        e.calcInch * unitMap[preferences.length]
      ).toFixed(1),
      [`Capacity (${preferences.volume})`]: Math.round(
        e.capacityGallons * unitMap[preferences.volume]
      ),
      "Fluid Brand": e.fluidBrand,
      "Fluid Segment": e.fluidSegment,
      "Fluid Product Type": e.fluidProductType,
    };
  });

  return (
    <Button
      // fullWidth={width === 'xs'}
      variant="contained"
      color="primary"
      component={CSVLink}
      filename={filename}
      data={data}
      className={isSmall ? classes.smallScreenMargin : classes.root}
    >
      <FileDownload className={classes.icon} /> CSV
    </Button>
  );
};

const styles = (theme) => ({
  root: { marginLeft: theme.spacing(1) },
  icon: { marginRight: theme.spacing(1) },
  smallScreenMargin: { marginLeft: theme.spacing(0) },
});

export default compose(withWidth(), withStyles(styles))(DownloadLocationCsv);
