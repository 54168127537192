import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {
  withStyles,
  FormControl,
  TextField,
  Paper,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import { Field } from 'react-final-form';

const subscriptionKey = process.env.REACT_APP_AZURE_MAPS_SUBSCRIPTION_KEY;
const addresssGeocodeServiceUrlTemplate = 'https://atlas.microsoft.com/search/address/json?typeahead=true&subscription-key={subscription-key}&api-version=1&query={query}&language={language}&countrySet={countrySet}&limit=5';

const requestUrl = addresssGeocodeServiceUrlTemplate
  .replace('{subscription-key}', subscriptionKey)
  .replace('{language}', 'en-US')
  .replace('{countrySet}', 'US'); // A comma seperated string of country codes to limit the suggestions to.

function renderInputComponent(inputProps) {
  const {
    classes, inputRef = () => {}, ref, ...other
  } = inputProps;

  return (
    <TextField
      fullWidth
      variant="filled"
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => (part.highlight ? (
          <span key={String(index)} style={{ fontWeight: 500 }}>
            {part.text}
          </span>
        ) : (
          <strong key={String(index)} style={{ fontWeight: 300 }}>
            {part.text}
          </strong>
        )))}
      </div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion;
}

class LocationAutosuggest extends React.Component {
  state = {
    suggestions: [],
    selectedSuggestion: undefined,
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength > 2) {
      fetch(requestUrl.replace('{query}', inputValue))
        .then(response => response.json())
        .then((data) => {
          this.setState({
            suggestions: data.results.map(each => ({
              name: each.address.freeformAddress,
              country: each.address.country,
              state: each.address.countrySubdivisionName
              || each.address.countrySubdivision,
              city: each.address.municipality,
              postalCode: each.address.postalCode,
              lat: each.position.lat,
              lon: each.position.lon,
              street: each.address.freeformAddress.split(',')[0],
            })),
          });
        });
    }
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = input => (event, { newValue }) => {
    this.setState({ selectedSuggestion: undefined });
    let value = newValue;
    if (!newValue.name) {
      value = { name: newValue };
    }
    input.onChange(value);
  };

  handleSuggestionSelected = input => (event, { suggestion }) => {
    input.onChange(suggestion);
    this.setState({ selectedSuggestion: suggestion });
  };

  render() {
    const {
      classes, input, errorText, isRequired,
    } = this.props;
    const { suggestions, selectedSuggestion } = this.state;

    const autosuggestProps = {
      renderInputComponent,
      suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion,
    };

    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl} error={!!errorText}>
          <Autosuggest
            {...autosuggestProps}
            inputProps={{
              classes,
              label: `Address${isRequired ? ' *' : ''}`,
              placeholder: 'Search an address',
              name: input.name,
              ...input,
              error: !!errorText,
              onBlur: () => {
                if (!selectedSuggestion) {
                  input.onChange('');
                }
                if (!input.value) {
                  this.setState({ selectedSuggestion: undefined });
                }
              },
              onChange: this.handleChange(input),
              value: input.value.name || '',
              size: 'small',
            }}
            theme={{
              container: classes.container,
              suggestionsContainerOpen: classes.suggestionsContainerOpen,
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion,
            }}
            onSuggestionSelected={this.handleSuggestionSelected(input)}
            renderSuggestionsContainer={options => (
              <Paper {...options.containerProps} square>
                {options.children}
              </Paper>
            )}
          />
          {errorText && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
        <div>
          <TextField
            label="Street"
            disabled
            value={selectedSuggestion?.street || input.value?.street || ''}
            variant="filled"
            size="small"
            InputLabelProps={{ shrink: !!(selectedSuggestion?.street || input.value?.street) }}
            className={classes.formControl}
            required
          />
        </div>
        <div>
          <TextField
            label="City"
            disabled
            value={selectedSuggestion?.city || input.value?.city || ''}
            variant="filled"
            size="small"
            InputLabelProps={{ shrink: !!(selectedSuggestion?.city || input.value?.city) }}
            className={classes.formControl}
            required
          />
        </div>
        <div>
          <TextField
            label="State"
            disabled
            value={selectedSuggestion?.state || input.value?.state || ''}
            variant="filled"
            size="small"
            InputLabelProps={{ shrink: !!(selectedSuggestion?.state || input.value?.state) }}
            className={classes.formControl}
            required
          />
        </div>
        <div>
          <TextField
            label="Zip"
            disabled
            value={selectedSuggestion?.postalCode || input.value?.postalCode || ''}
            variant="filled"
            size="small"
            InputLabelProps={{ shrink: !!(selectedSuggestion?.postalCode || input.value?.postalCode) }}
            className={classes.formControl}
            required
          />
        </div>
        <div>
          <TextField
            label="Country"
            disabled
            value={selectedSuggestion?.country || input.value?.country || ''}
            variant="filled"
            size="small"
            InputLabelProps={{ shrink: !!(selectedSuggestion?.country || input.value?.country) }}
            className={classes.formControl}
            required
          />
        </div>
      </div>
    );
  }
}

LocationAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    width: 400,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1000,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
});

const StyledLocationAutoSuggest = withStyles(styles)(LocationAutosuggest);

const renderAddressInput = ({ input, meta: { touched, error } }, isRequired) => (
  <StyledLocationAutoSuggest errorText={touched && error} input={input} isRequired={isRequired} />
);

const AddressInput = ({ source, validate, ...rest }) => (
  <Field
    name={source}
    component={renderAddressInput}
    isRequired={validate.isRequired}
    validate={validate}
    {...rest}
  />
);

export default AddressInput;


// 600 Phillips Drive, Coppell, TX 75019
// Denton Tap Road, Lewisville, TX 75067
// 7911 Denton Street, Houston, TX 77028
// 603 South Central Expressway, Anna, TX 75409
// 602 US-601 South, Concord, NC 28025
// 604 South Central Expressway, Anna, TX 75409
// 7912 Denton Street, Houston, TX 77028
// 7910 Denton Street, Houston, TX 77028
// Denton Highway, Watauga, TX
// 601 South Central Expressway, McKinney, TX 75070
