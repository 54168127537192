import React from "react";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import { Title, useDataProvider } from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import OpenInNew from "@material-ui/icons/OpenInNew";
import Skeleton from "react-loading-skeleton";

import {
  ActionPlanPanel,
  GuagePanel,
  SingleStatPanel,
  NoTankPanel,
} from "./panels";
import { LocationFilter, TimeFilter } from "./filters";
import PreventionKitPanel from "./panels/PreventionKitPanel";
import * as actions from "../store/actions";
import DownloadLocationCsv from "../components/DownloadLocationCsv";
import unitMap from "../utils/unitMap";
import { TimezoneContext } from "../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  tankButton: {
    marginBottom: theme.spacing(2),
  },
  row: {
    marginBottom: theme.spacing(4),
  },
  list: {
    width: "100%",
  },
  fullHeight: {
    display: "flex",
    height: 131,
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
}));

const LocationDashboard = () => {
  const preferences = JSON.parse(localStorage.getItem("preferences"));
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const views = useSelector((state) => state.admin.ui.viewVersion);
  const selectedLocation = useSelector(
    (state) => state.locationData.selectedLocation
  );
  const tanks = useSelector((state) => state.tankData.tanks);
  const selectedTimeRange = useSelector(
    (state) => state.timeRangeData.selectedTimeRange
  );
  const [lastData, setLastData] = React.useState();
  const { timezone } = React.useContext(TimezoneContext);
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();

  const onLocationSelectedHandler = (location) => {
    setLastData(undefined);
    dispatch({
      type: actions.SET_SELECTED_TANK,
      value: undefined,
    });
    dispatch({
      type: actions.SET_SELECTED_LOCATION,
      value: {
        id: location.id,
        desc: `${location.name}, ${location.address.name}`,
        timezone: location.timezone,
      },
    });
  };

  React.useEffect(() => {
    async function fetchLastData(locationId, fromTime, toTime) {
      const response = await dataProvider
        .getAll("location/last", {
          filter: { locationId, fromTime, toTime },
        })
        .then(({ data }) => data);
      response.sort((a, b) => a.fluid_product.localeCompare(b.fluid_product));
      // eslint-disable-next-line no-nested-ternary
      response.sort((a, b) =>
        a.tank_no > b.tank_no ? 1 : b.tank_no > a.tank_no ? -1 : 0
      );
      const response1 = response.filter((tank) => tank.tank_status === "Yes");
      setLastData(response1);
    }

    if (selectedLocation && selectedTimeRange) {
      setLastData(undefined);
      dispatch({
        type: actions.SET_SELECTED_TANK,
        value: undefined,
      });
      const start = moment(
        selectedTimeRange.start(timezone) * 1000
      ).toISOString();
      const end = moment(selectedTimeRange.end(timezone) * 1000).toISOString();
      fetchLastData(selectedLocation.id, start, end);
    }
  }, [
    selectedLocation,
    selectedTimeRange,
    dataProvider,
    views,
    dispatch,
    timezone,
  ]);

  function onClick(tankId) {
    dispatch({
      type: actions.SET_SELECTED_TANK,
      value: tanks.find((tank) => tank.id === tankId),
    });
  }

  const skeletons = [undefined, undefined, undefined, undefined, undefined];

  return (
    <Box m={1}>
      <Title title="Location" />
      <Grid
        className={classes.root}
        container
        justify="space-between"
        spacing={2}
      >
        <Grid item xs={isSmall && 12}>
          <LocationFilter onSelected={onLocationSelectedHandler} />
          {lastData && (
            <DownloadLocationCsv
              lastData={lastData}
              filename={`${moment(
                selectedTimeRange.end() * 1000
              ).toISOString()}_${selectedLocation.desc}.csv`}
              timeRangeLabel={selectedTimeRange.label}
            />
          )}
        </Grid>
        <Grid item xs={isSmall && 12}>
          <TimeFilter />
        </Grid>
      </Grid>
      <br />

      <Grid item container spacing={1} className={classes.row}>
        <Grid item lg={4} xs={12}>
          <PreventionKitPanel
            compact
            locationId={selectedLocation && selectedLocation.id}
            fromTime={selectedTimeRange.start(timezone) * 1000}
            toTime={selectedTimeRange.end(timezone) * 1000}
          />
        </Grid>
      </Grid>
      {lastData?.length === 0 && <NoTankPanel />}
      {(lastData || skeletons).map((each, index) => (
        <Grid
          item
          container
          spacing={1}
          className={classes.row}
          key={each ? each.id : index}
        >
          <Grid item xs={12} className={classes.tankButton}>
            {each ? (
              <Button
                variant="contained"
                color="default"
                component={Link}
                onClick={() => {
                  onClick(each.tankId);
                }}
                to="/dashboard/tank"
              >
                {(each || {}).desc}
                <OpenInNew className={classes.button} />
              </Button>
            ) : (
              <Skeleton height={40} width={256} />
            )}
          </Grid>
          <Grid item container spacing={1} xs={12} lg={6}>
            <Grid item xs={12} lg={7}>
              <ActionPlanPanel
                compact
                empty={(each || {}).empty}
                lastData={each}
                threshold={(each || {}).threshold}
                operator={(each || {}).operator}
                ruleName={(each || {}).rule_name}
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <GuagePanel
                value={each && Math.round(each.percent)}
                compact
                fluidState={each && each.fluidState}
                desc={
                  each && each.avg !== undefined && each.days !== undefined
                    ? `${(each.avg * unitMap[preferences.volume]).toFixed(
                        2
                      )} ${preferences?.volume?.toLowerCase()} ${
                        each.fluidState === "New" ? "used" : "added"
                      } per day`
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1} xs={12} lg={6}>
            <Grid item xs={6} lg={4}>
              <SingleStatPanel
                stat={each && each.empty}
                desc={
                  each
                    ? `Estimated Days Before ${
                        each.fluidState === "New" ? "Empty" : "Full"
                      }`
                    : ""
                }
                compact
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <SingleStatPanel
                stat={each && each.capacityGallons - each.calcGal}
                desc={
                  each
                    ? `Volume Needed to ${
                        each.fluidState === "New" ? "Fill" : "Empty"
                      } the Tank in ${preferences.volume}`
                    : ""
                }
                unit="volume"
                compact
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <SingleStatPanel
                stat={each && each.calcGal}
                desc={`Current Volume in ${preferences.volume}`}
                unit="volume"
                compact
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <SingleStatPanel
                stat={each && each.calcInch}
                rounded={false}
                desc={`Current Volume in ${preferences.length}`}
                unit="length"
                compact
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <SingleStatPanel
                stat={each && each.capacityGallons}
                desc={`Capacity in ${preferences.volume}`}
                unit="volume"
                compact
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              {each ? (
                <Paper className={classes.fullHeight}>
                  <List className={classes.list}>
                    {[
                      each.fluidBrand,
                      each.fluidSegment,
                      each.fluidProductType,
                    ].map((prop, index) => (
                      <React.Fragment key={`${index}-${each.fluidBrand}`}>
                        {index !== 0 && <Divider />}
                        <ListItem className={classes.padding}>
                          <ListItemText
                            secondary={prop}
                            className={classes.listItemTextPadding}
                          />
                        </ListItem>
                      </React.Fragment>
                    ))}
                  </List>
                </Paper>
              ) : (
                <Skeleton height="100%" />
              )}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default LocationDashboard;
