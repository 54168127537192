import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import {
	List,
	SimpleList,
	Datagrid,
	TextField,
	EmailField,
	ReferenceField,
	ReferenceInput,
	SelectInput,
	// FunctionField,
	Filter,
	TextInput,
} from 'react-admin'
import BulkActionButtons from '../components/BulkActionButtons'
import PostPagination from '../components/CustomPagination'
import PhoneField from '../components/PhoneField'
// import timestampFormat from '../components/DateFormat';

const LocationFilter = (props) => (
	<Filter {...props}>
		<TextInput source='location_no' />
		<TextInput source='name' />
		<TextInput source='phone' />
		<TextInput source='email' />
		<ReferenceInput
			label='Customer'
			source='customer_id'
			reference='customers'
			sort={{ field: 'name', order: 'ASC' }}>
			<SelectInput optionText='name' />
		</ReferenceInput>
		<TextInput source='address.street' label='Street' />
		<TextInput source='address.city' label='City' />
		<TextInput source='address.state' label='State' />
		<TextInput source='address.postalCode' label='Zip' />
		<TextInput source='address.country' label='Country' />
		<TextInput source='timezone' label='Timezone' />
	</Filter>
)

export default ({ permissions, ...props }) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))
	return (
		<List
			{...props}
			bulkActionButtons={<BulkActionButtons permissions={permissions} />}
			filters={<LocationFilter />}
			pagination={<PostPagination />}>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => record.name}
					secondaryText={(record) => record.address.name}
				/>
			) : (
				<Datagrid rowClick='edit'>
					<TextField source='location_no' />
					<TextField source='name' />
					<PhoneField source='phone' />
					<EmailField source='email' />
					<TextField source='mobile' />
					<ReferenceField source='customer_id' reference='customers' allowEmpty>
						<TextField source='name' />
					</ReferenceField>
					<TextField source='address.street' label='Street' />
					<TextField source='address.city' label='City' />
					<TextField source='address.state' label='State' />
					<TextField source='address.postalCode' label='Zip' />
					<TextField source='address.country' label='Country' />
					<TextField source='timezone' />
					{/* <FunctionField
            source="created_at"
            render={record => timestampFormat(record.created_at)}
          />
          <FunctionField
            source="updated_at"
            render={record => timestampFormat(record.updated_at)}
          /> */}
				</Datagrid>
			)}
		</List>
	)
}
