import TankManufacturerIcon from '@material-ui/icons/Domain';
import TankManufacturerList from './TankManufacturerList';
import TankManufacturerEdit from './TankManufacturerEdit';
import TankManufacturerCreate from './TankManufacturerCreate';

export default {
  list: TankManufacturerList,
  edit: TankManufacturerEdit,
  create: TankManufacturerCreate,
  icon: TankManufacturerIcon,
};
