import React from 'react';
import { Admin, Resource } from 'react-admin';

import { createMuiTheme } from '@material-ui/core';
import customRoutes from './customRoutes';
import history from './utils/history';

// providers
import dataProvider from './dataProvider';
import authProvider from './authProvider';

// layouts
import appLayout from './layouts';
import LogoutButton from './layouts/LogoutButton';

// dashboard
import TankDashboard from './dashboard/TankDashboard';
import LocationDashboard from './dashboard/LocationDashboard';
import DistributorDashboard from './dashboard/DistributorDashboard';

// login
import { LoginPage } from './login';

// admin
import users from './admin/users';
import references from './admin/references';
import manufacturers from './admin/manufacturers';
import distributors from './admin/distributors';
import locations from './admin/locations';
import tanks from './admin/tanks';
import rules from './admin/rules';
import retailers from './admin/retailers';
import customers from './admin/customers';
import tankManufacturers from './admin/tankManufacturers';
import InventoryReport from './reports/InventoryReport';
import { AuthContext } from './contexts';

const Dashboard = () => {
  const dashboardAccess = localStorage.getItem('dashboardAccess');

  const { setAuthenticated } = React.useContext(AuthContext);
  React.useEffect(() => {
    setAuthenticated({ authenticated: true, setAuthenticated });
  }, [setAuthenticated]);

  if (!dashboardAccess || (dashboardAccess && dashboardAccess.length === 0)) {
    return null;
  }

  if (dashboardAccess[0] === 'distributor') {
    return <DistributorDashboard />;
  }

  if (dashboardAccess[0] === 'location') {
    return <LocationDashboard />;
  }

  if (dashboardAccess[0] === 'tank') {
    return <TankDashboard />;
  }

  return null;
};

const Reports = () => <InventoryReport />;

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#528dda',
      main: '#0060a8',
      dark: '#003778',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

const AdminPage = () => (
  <Admin
    dashboard={Dashboard}
    reports={Reports}
    loginPage={LoginPage}
    theme={theme}
    customRoutes={customRoutes}
    layout={appLayout}
    dataProvider={dataProvider}
    history={history}
    authProvider={authProvider}
    logoutButton={LogoutButton}
  >
    {permissions => (permissions.role === 'Root' || permissions.role === 'Admin'
      ? [
        <Resource name="users" options={{ label: 'Users', admin: true }} {...users} />,
        <Resource
          name="references"
          options={{ label: 'References', admin: true }}
          {...references}
        />,
        <Resource
          name="manufacturers"
          options={{ label: 'Fluid manufacturers', admin: true }}
          {...manufacturers}
        />,
        <Resource
          name="retailers"
          options={{ label: 'Retailers', admin: true }}
          {...retailers}
        />,
        <Resource
          name="customers"
          options={{ label: 'Customers', admin: true }}
          {...customers}
        />,
        <Resource
          name="distributors"
          options={{ label: 'Service Providers', admin: true }}
          {...distributors}
        />,
        <Resource
          name="locations"
          options={{ label: 'Locations', admin: true }}
          {...locations}
        />,
        <Resource name="tanks" options={{ label: 'Tanks', admin: true }} {...tanks} />,
        <Resource name="tank-manufacturers" options={{ label: 'Tank Manufacturers', admin: true }} {...tankManufacturers} />,
        <Resource name="rules" options={{ label: 'Rules', admin: true }} {...rules} />,
      ]
      : [
        <Resource name="users" />,
        <Resource name="references" />,
        <Resource name="manufacturers" />,
        <Resource name="distributors" />,
        <Resource name="locations" />,
        <Resource name="tanks" />,
        <Resource name="tank-manufacturers" />,
        <Resource name="rules" />,
      ])
    }
  </Admin>
);
export default AdminPage;
