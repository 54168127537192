import RetailerIcon from '@material-ui/icons/Store';
import CustomerList from './CustomerList';
import CustomerEdit from './CustomerEdit';
import CustomerCreate from './CustomerCreate';

export default {
  list: CustomerList,
  edit: CustomerEdit,
  create: CustomerCreate,
  icon: RetailerIcon,
};
