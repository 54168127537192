import React from 'react';

const formatPhoneNumber = (phone) => {
  const cleaned = (`${phone}`).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return null;
};

const PhoneField = ({ source, record = {} }) => (
  <span>{record[source] && formatPhoneNumber(record[source])}</span>
);

export default PhoneField;
