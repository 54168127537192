import TankIcon from '@material-ui/icons/Gradient';
import TankList from './TankList';
import TankEdit from './TankEdit';
import TankCreate from './TankCreate';

export default {
  list: TankList,
  edit: TankEdit,
  create: TankCreate,
  icon: TankIcon,
};
