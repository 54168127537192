import ReferenceIcon from '@material-ui/icons/BorderAll';
import ReferenceList from './ReferenceList';
import ReferenceEdit from './ReferenceEdit';
import ReferenceCreate from './ReferenceCreate';

export default {
  list: ReferenceList,
  edit: ReferenceEdit,
  create: ReferenceCreate,
  icon: ReferenceIcon,
};
