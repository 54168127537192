import moment from 'moment-timezone'
import { decodeToken } from './utils/helper'

const apiUrl = process.env.REACT_APP_API_URL
const checkAuth = (params) => {
	if (params.key) {
		const dashboardAccess = JSON.parse(localStorage.getItem('dashboardAccess'))

		if (
			params.key === '/dashboard/distributor' &&
			params.params === 'distributor-dashboard'
		) {
			return dashboardAccess.includes('distributor')
				? Promise.resolve()
				: Promise.reject({ redirectTo: '/no-access' })
		}

		if (
			params.key === '/dashboard/location' &&
			params.params === 'location-dashboard'
		) {
			return dashboardAccess.includes('location')
				? Promise.resolve()
				: Promise.reject({ redirectTo: '/no-access' })
		}

		if (
			params.key === '/dashboard/tank' &&
			params.params === 'tank-dashboard'
		) {
			return dashboardAccess.includes('tank')
				? Promise.resolve()
				: Promise.reject({ redirectTo: '/no-access' })
		}
	}

	const token = localStorage.getItem('token')
	return token ? Promise.resolve() : Promise.reject()
}

const authProvider = {
	login: async (params) => {
		const { username, password } = params
		const request = new Request(`${apiUrl}/login`, {
			method: 'POST',
			body: JSON.stringify({ username, password }),
			headers: new Headers({ 'Content-Type': 'application/json' }),
		})
		const response = await fetch(request)
		if (response.status < 200 || response.status >= 300) {
			throw new Error(response.statusText)
		}
		const { token } = await response.json()
		if (!token) {
			return Promise.reject()
		}
		const {
			data: { id, name, role, dashboard_access: dashboardAccess, preferences },
			exp,
		} = decodeToken(token)

		return new Promise((resolve) => {
			localStorage.setItem('id', id)
			localStorage.setItem('name', name)
			localStorage.setItem('role', role)
			localStorage.setItem('dashboardAccess', JSON.stringify(dashboardAccess))
			localStorage.setItem('preferences', JSON.stringify(preferences))
			localStorage.setItem('token', token)
			localStorage.setItem('exp', exp)
			return setInterval(() => resolve(), 1000)
		})
	},
	logout: (params) => {
		localStorage.clear()
		return Promise.resolve()
	},
	checkAuth: async (params) => {
		const expiresAt = localStorage.getItem('exp')
		if (
			expiresAt &&
			moment(new Date())
				.add(10, 'minutes')
				.unix() > parseInt(expiresAt, 10)
		) {
			const storedToken = localStorage.getItem('token')
			if (storedToken) {
				const request = new Request(`${apiUrl}/refresh-token`, {
					method: 'POST',
					body: null,
					headers: new Headers({
						'Content-Type': 'application/json',
						Authorization: `Bearer ${storedToken}`,
					}),
				})
				const response = await fetch(request)
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText)
				}
				const { token } = await response.json()
				if (!token) {
					return Promise.reject()
				}
				const { exp } = decodeToken(token)
				localStorage.setItem('token', token)
				localStorage.setItem('exp', exp)
				return checkAuth(params)
			}
		}

		return checkAuth(params)
	},
	checkError: (error) => {
		const { status } = error
		if (status === 401 || status === 403) {
			localStorage.clear()
			return Promise.reject()
		}
		return Promise.resolve()
	},
	getPermissions: (params) => {
		const token = localStorage.getItem('token')
		if (token) {
			const {
				data: { role, dashboard_access: dashboardAccess },
			} = decodeToken(token)
			return dashboardAccess && role
				? Promise.resolve({
						role,
						dashboardAccess: JSON.stringify(dashboardAccess),
				  })
				: Promise.reject()
		}
		return Promise.resolve([])
	},
}

export default authProvider
