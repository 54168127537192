import { useMediaQuery } from "@material-ui/core";
// eslint-disable-next-line import/no-duplicates
import React from "react";
import { cloneElement } from "react";
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  FunctionField,
  ReferenceField,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  BooleanField,
  NumberInput,
  NumberField,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
} from "react-admin";
import SelectColumn from "./SelectColumns";
import BulkActionButtons from "../components/BulkActionButtons";
import PostPagination from "../components/CustomPagination";

const TankFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="retailer_id"
      reference="retailers"
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="location_id"
      reference="locations"
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Tank manufacturer"
      source="tank_manufacturer_id"
      reference="tank-manufacturers"
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Fluid manufacturer"
      source="manufacturer_id"
      reference="manufacturers"
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="distributor_id"
      reference="distributors"
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Fluid type"
      source="fluid_type_ref_id"
      reference="references"
      filter={{ ref_category: "FLUID_TYPE" }}
      sort={{ field: "ref_value", order: "ASC" }}
    >
      <SelectInput optionText="ref_value" />
    </ReferenceInput>
    <ReferenceInput
      label="Fluid state"
      source="fluid_state_ref_id"
      reference="references"
      sort={{ field: "ref_value", order: "ASC" }}
      filter={{ ref_category: "FLUID_STATE" }}
    >
      <SelectInput optionText="ref_value" />
    </ReferenceInput>
    <TextInput source="fluid_brand" />
    <ReferenceInput
      label="Fluid segment"
      source="fluid_segment_ref_id"
      reference="references"
      sort={{ field: "ref_value", order: "ASC" }}
      filter={{ ref_category: "FLUID_SEGMENT" }}
    >
      <SelectInput optionText="ref_value" />
    </ReferenceInput>
    <ReferenceInput
      label="Fluid product"
      source="fluid_product_type_ref_id"
      reference="references"
      perPage={50}
      sort={{ field: "ref_value", order: "ASC" }}
      filter={{ ref_category: "FLUID_PRODUCT_TYPE" }}
    >
      <SelectInput optionText="ref_value" />
    </ReferenceInput>
    <NumberInput source="capacity_gallons" />
    <NumberInput source="depth_inches" />
    <TextInput source="sensor_supplier_name" />
    <ReferenceInput
      label="Sensor tech type"
      source="sensor_tech_type_ref_id"
      reference="references"
      sort={{ field: "ref_value", order: "ASC" }}
      filter={{ ref_category: "SENSOR_TECH" }}
    >
      <SelectInput optionText="ref_value" />
    </ReferenceInput>
    <TextInput source="stream_id" />
    <TextInput source="serial_no" />
    <TextInput source="location_no" />
    <BooleanInput source="is_simulated" />
    <BooleanInput source="is_active" defaultValue />
    {/* <TextField label="Tank Status" source="tank_status" /> */}
    <SelectInput
      source="tank_status"
      choices={[
        { id: "Yes", name: "Active" },
        { id: "No", name: "Inactive" },
        { id: "Pending", name: "Pending" },
      ]}
    />
    <NumberInput source="curr_calibration" label="Current Calibration" />
  </Filter>
);

const ListActions = (props) => {
  const { className, filters, maxResults, setColumns, ...rest } = props;
  const { total } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <SelectColumn setColumns={setColumns} />
      {cloneElement(filters, { context: "button" })}
      <CreateButton />
      <ExportButton disabled={total === 0} maxResults={maxResults} />
    </TopToolbar>
  );
};

export default ({ permissions, ...props }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const selectedTanksColumns =
    JSON.parse(localStorage.getItem("tankColumns")) || [];
  const [columns, setColumns] = React.useState(selectedTanksColumns);
  const filteredColumns = columns.filter((el) => el != null);
  // console.log("props", selectedTanksColumns);
  return (
    <div>
      <List
        {...props}
        bulkActionButtons={<BulkActionButtons permissions={permissions} />}
        filters={<TankFilter />}
        pagination={<PostPagination />}
        actions={<ListActions setColumns={setColumns} />}
      >
        {isSmall ? (
          <SimpleList
            primaryText={(record) => record.fluid_brand}
            secondaryText={(record) => (
              <ReferenceField
                label="Fluid Product Type"
                source="fluid_product_type_ref_id"
                reference="references"
                basePath="references"
                record={record}
                link={false}
              >
                <TextField source="ref_value" />
              </ReferenceField>
            )}
            tertiaryText={(record) =>
              `${record.capacity_gallons} gal, ${record.depth_inches} in `
            }
          />
        ) : (
          <Datagrid rowClick="edit">
            <NumberField label="Tank position no" source="tank_no" />
            <ReferenceField
              source="location_id"
              reference="locations"
              allowEmpty
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              label="Fluid state"
              source="fluid_state_ref_id"
              reference="references"
              allowEmpty
              link={false}
            >
              <TextField source="ref_value" />
            </ReferenceField>
            <ReferenceField
              label="Fluid product"
              source="fluid_product_type_ref_id"
              reference="references"
              allowEmpty
              link={false}
            >
              <TextField source="ref_value" />
            </ReferenceField>
            <TextField label="Stream id" source="stream_id" />

            {filteredColumns.map((col) =>
              col.source ? (
                <ReferenceField
                  label={col.title}
                  source={col.source}
                  reference={col.reference}
                  allowEmpty
                  link={col.link}
                >
                  <TextField source={col.textSource} />
                </ReferenceField>
              ) : (
                <TextField source={col.textSource} />
              )
            )}
            <BooleanField source="is_simulated" />
            {/* <FunctionField
              source="is_active"
              render={(record) => (record.is_active ? "Yes" : "No")}
            /> */}

            <TextField label="Tank Status" source="tank_status" />
            {/* <ReferenceField reference="references" source="tank_status">
              <TextField source="name" />
            </ReferenceField> */}
          </Datagrid>
        )}
      </List>
    </div>
  );
};
