import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import {
	List,
	SimpleList,
	Datagrid,
	TextField,
	EmailField,
	// FunctionField,
	Filter,
	TextInput,
} from 'react-admin'

import BulkActionButtons from '../components/BulkActionButtons'
import PostPagination from '../components/CustomPagination'
import PhoneField from '../components/PhoneField'
// import timestampFormat from '../components/DateFormat';

const ManufacturerFilter = (props) => (
	<Filter {...props}>
		<TextInput source='name' />
		<TextInput source='phone' />
		<TextInput source='email' />
		<TextInput source='address.street' label='Street' />
		<TextInput source='address.city' label='City' />
		<TextInput source='address.state' label='State' />
		<TextInput source='address.postalCode' label='Zip' />
		<TextInput source='address.country' label='Country' />
	</Filter>
)

export default ({ permissions, ...props }) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))
	return (
		<List
			{...props}
			exporter={false}
			bulkActionButtons={<BulkActionButtons permissions={permissions} />}
			filters={<ManufacturerFilter />}
			pagination={<PostPagination />}>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => record.name}
					secondaryText={(record) => record.address.name}
				/>
			) : (
				<Datagrid rowClick='edit'>
					<TextField source='name' />
					<PhoneField source='phone' />
					<EmailField source='email' />
					<TextField source='address.street' label='Street' />
					<TextField source='address.city' label='City' />
					<TextField source='address.state' label='State' />
					<TextField source='address.postalCode' label='Zip' />
					<TextField source='address.country' label='Country' />
					{/* <FunctionField
            source="created_at"
            render={record => timestampFormat(record.created_at)}
          />
          <FunctionField
            source="updated_at"
            render={record => timestampFormat(record.updated_at)}
          /> */}
				</Datagrid>
			)}
		</List>
	)
}
