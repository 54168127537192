import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import {
	List,
	SimpleList,
	Datagrid,
	TextField,
	EmailField,
	// FunctionField,
	Filter,
	TextInput,
} from 'react-admin'
import BulkActionButtons from '../components/BulkActionButtons'
import PostPagination from '../components/CustomPagination'
import PhoneField from '../components/PhoneField'
// import timestampFormat from '../components/DateFormat';

const DistributorFilter = (props) => (
	<Filter {...props}>
		<TextInput source='distributor_no' />
		<TextInput source='name' />
		<TextInput source='phone' />
		<TextInput source='email' />
		<TextInput source='address.street' label='Street' />
		<TextInput source='address.city' label='City' />
		<TextInput source='address.state' label='State' />
		<TextInput source='address.postalCode' label='Zip' />
		<TextInput source='address.country' label='Country' />
	</Filter>
)

export default ({ permissions, ...props }) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))
	return (
		<List
			{...props}
			exporter={false}
			bulkActionButtons={<BulkActionButtons permissions={permissions} />}
			filters={<DistributorFilter />}
			pagination={<PostPagination />}>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => record.name}
					secondaryText={(record) => record.address.name}
				/>
			) : (
				<Datagrid rowClick='edit'>
					<TextField source='distributor_no' label='Service provider no' />
					<TextField source='name' />
					<PhoneField source='phone' />
					<EmailField source='email' />
					<TextField source='address.street' label='Street' />
					<TextField source='address.city' label='City' />
					<TextField source='address.state' label='State' />
					<TextField source='address.postalCode' label='Zip' />
					<TextField source='address.country' label='Country' />
				</Datagrid>
			)}
		</List>
	)
}
