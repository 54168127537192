import React from 'react';
import {
  Create,
  TabbedForm,
  FormTab,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  TextInput,
  BooleanInput,
  CheckboxGroupInput,
  SelectArrayInput,
  required,
  minLength,
  email,
  useDataProvider,
} from 'react-admin';

const redirect = () => '/users';

const validateEmail = [required(), email()];
const validateRequired = required();
const validateName = [required(), minLength(2)];
const validatePassword = [required(), minLength(6)];

export default (props) => {
  const dataProvider = useDataProvider();
  const [defaults, setDefaults] = React.useState({
    VOLUME_UNIT: undefined,
    LENGTH_UNIT: undefined,
    DATE_FORMAT: undefined,
    TIME_FORMAT: undefined,
  });

  React.useEffect(() => {
    async function fetchDefaults() {
      const results = await Promise.all([
        dataProvider.getAll('references', { filter: { ref_category: 'VOLUME_UNIT', ref_value: 'gal' } }).then(({ data }) => data),
        dataProvider.getAll('references', { filter: { ref_category: 'LENGTH_UNIT', ref_value: 'in' } }).then(({ data }) => data),
        dataProvider.getAll('references', { filter: { ref_category: 'DATE_FORMAT', ref_value: 'MM-DD-YY' } }).then(({ data }) => data),
        dataProvider.getAll('references', { filter: { ref_category: 'TIME_FORMAT', ref_value: '12 Hour' } }).then(({ data }) => data),
      ]);
      const _defaults = {};
      if (results[0] && results[0].length > 0) {
        _defaults.VOLUME_UNIT = results[0][0].id;
      }
      if (results[1] && results[1].length > 0) {
        _defaults.LENGTH_UNIT = results[1][0].id;
      }
      if (results[2] && results[2].length > 0) {
        _defaults.DATE_FORMAT = results[2][0].id;
      }
      if (results[3] && results[3].length > 0) {
        _defaults.TIME_FORMAT = results[3][0].id;
      }
      setDefaults(_defaults);
    }

    fetchDefaults();
  }, []);


  return (
    <Create {...props}>
      <TabbedForm redirect={redirect}>
        <FormTab label="Summary">
          <TextInput source="name" validate={validateName} />
          <TextInput source="email" validate={validateEmail} />
          <TextInput source="password" type="password" validate={validatePassword} />

          <BooleanInput source="is_active" defaultValue />
        </FormTab>
        <FormTab label="Access Control">
          <ReferenceInput
            label="Role"
            source="role_ref_id"
            reference="references"
            validate={validateRequired}
            sort={{ field: 'ref_category', order: 'ASC' }}
            filter={{ ref_category: 'ROLE' }}
          >
            <SelectInput optionText="ref_value" />
          </ReferenceInput>
          <CheckboxGroupInput
            label="Dashboard Access"
            source="dashboard_access"
            choices={[
              { id: 'distributor', name: 'Distributor' },
              { id: 'location', name: 'Location' },
              { id: 'tank', name: 'Tank' },
            ]}
            defaultValue={['distributor', 'location', 'tank']}
          />
          <ReferenceArrayInput
            label="Retailers"
            source="retailers"
            reference="retailers"
            style={{ width: '500px' }}
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            label="Manufacturers"
            source="manufacturers"
            reference="manufacturers"
            style={{ width: '500px' }}
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            label="Distributors"
            source="distributors"
            reference="distributors"
            style={{ width: '500px' }}
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            label="Locations"
            source="locations"
            reference="locations"
            style={{ width: '500px' }}
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
        </FormTab>
        <FormTab label="Preferences">
          {defaults.VOLUME_UNIT && (
            <ReferenceInput
              source="volume_ref_id"
              label="Volume unit"
              reference="references"
              sort={{ field: 'ref_value', order: 'ASC' }}
              filter={{ ref_category: 'VOLUME_UNIT' }}
              validate={validateRequired}
              defaultValue={defaults.VOLUME_UNIT}
            >
              <SelectInput optionText="ref_value" />
            </ReferenceInput>
          )}
          {defaults.LENGTH_UNIT && (
            <ReferenceInput
              source="length_ref_id"
              label="Length unit"
              reference="references"
              sort={{ field: 'ref_value', order: 'ASC' }}
              filter={{ ref_category: 'LENGTH_UNIT' }}
              validate={validateRequired}
              defaultValue={defaults.LENGTH_UNIT}
            >
              <SelectInput optionText="ref_value" />
            </ReferenceInput>
          )}
          {defaults.DATE_FORMAT && (
            <ReferenceInput
              source="date_ref_id"
              label="Date format"
              reference="references"
              sort={{ field: 'ref_value', order: 'ASC' }}
              filter={{ ref_category: 'DATE_FORMAT' }}
              validate={validateRequired}
              defaultValue={defaults.DATE_FORMAT}
            >
              <SelectInput optionText="ref_value" />
            </ReferenceInput>
          )}
          {defaults.TIME_FORMAT && (
            <ReferenceInput
              source="time_ref_id"
              label="Time format"
              reference="references"
              sort={{ field: 'ref_value', order: 'ASC' }}
              filter={{ ref_category: 'TIME_FORMAT' }}
              validate={validateRequired}
              defaultValue={defaults.TIME_FORMAT}
            >
              <SelectInput optionText="ref_value" />
            </ReferenceInput>
          )}
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
