import React from 'react';
import PropTypes from 'prop-types';
import { TextField, makeStyles } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { useInput, ValidationError } from 'ra-core';


function TextMaskedInput(props) {
  const { inputRef, ...rest } = props;

  return (
    <MaskedInput
      {...rest}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask={false}
      guide={false}
    />
  );
}

TextMaskedInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    width: theme.spacing(32),
  },
});

const PhoneInput = (props) => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput({
    ...props,
    parse: inputValue => inputValue.toString().trim().replaceAll('-', ''),
  });
  const { label } = props;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <TextField
      name={name}
      label={label}
      required={isRequired}
      variant="filled"
      size="small"
      className={classes.formControl}
      InputProps={{
        inputComponent: TextMaskedInput,
        onChange,
        ...rest,
        required: isRequired,
      }}
      error={!!(touched && error)}
      helperText={touched && error && <ValidationError error={error} />}
    />
  );
};

PhoneInput.defaultProps = {
  label: 'Phone',
};

PhoneInput.propTypes = {
  label: PropTypes.string,
};

export default PhoneInput;

export const formatPhoneNumber = (phone) => {
  const cleaned = (`${phone}`).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return null;
};
