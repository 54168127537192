import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Paper,
  List,
  ListItem,
  Avatar,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { useDataProvider } from 'react-admin';
import { compose } from 'recompose';
import red from '@material-ui/core/colors/red';
import ActionPlanIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment-timezone';

const PreventionKitPanel = (props) => {
  const {
    classes, compact, locationId, fromTime, toTime,
  } = props;
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [routinePlan, setRoutinePlan] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const response = await dataProvider.getAll('prevention-kit', {
        filter: { locationId, fromTime, toTime },
      });
      setRoutinePlan(response.data.routine || false);
      setChecked(response.data.routine ? response.data.routine.prevention_kit.checked : false);
    }
    if (locationId && fromTime && toTime) {
      fetchData();
    }
  }, [dataProvider, locationId, fromTime, toTime]);

  const listItemClass = classnames(classes.listItem, compact && classes.padding);

  function handleChange() {
    setChecked(!checked);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAgree = async () => {
    setLoading(true);
    const alert = {
      location_id: locationId,
      action_plan: {
        prevention_kit: {
          text: 'Spill Prevention Kit',
          checked,
          user: localStorage.getItem('name'),
          at: moment().valueOf(),
        },
      },
    };
    await dataProvider.create('prevention-kit', { data: alert });
    setRoutinePlan(alert.action_plan);
    setLoading(false);
    setOpen(!open);
  };

  const handleCloseDisAgree = () => {
    setOpen(!open);
  };

  if (!routinePlan) {
    return <React.Fragment />;
  }

  return (
    <Paper className={compact ? classes.compactRoot : classes.root}>
      <List>
        {Object.keys(routinePlan).map((key, index) => (
          <ListItem className={listItemClass} key={index}>
            <Avatar className={classes.redAvatar}>
              <ActionPlanIcon />
            </Avatar>
            <ListItemText
              primary={routinePlan[key].text}
              secondary={
                routinePlan[key].user
                && `By ${routinePlan[key].user} at ${moment(routinePlan[key].at).format(
                  'DD/MM/YYYY, HH:mm:ss',
                )}`
              }
              className={compact ? classes.listItemTextPadding : null}
              primaryTypographyProps={compact ? { variant: 'body1' } : null}
              secondaryTypographyProps={{ variant: 'caption' }}
            />
            {routinePlan[key].checked === undefined ? null : (
              <ListItemSecondaryAction>
                <Checkbox checked={checked} onChange={handleChange} />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
      <Modal open={open}>
        <Dialog
          open
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Acknowledge Check Spill Prevention Kit?</DialogTitle>
          <DialogContent>
            {!checked ? (
              <DialogContentText id="alert-dialog-description">
                This means that you have checked the Spill Prevention Kit.
              </DialogContentText>
            ) : (
              <DialogContentText id="alert-dialog-description">
                This means that you have not checked the Spill Prevention Kit.
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDisAgree}>Cancel</Button>
            <Button onClick={handleCloseAgree}>
              {loading ? (
                <CircularProgress size={14} />
              ) : (
                <Typography color="primary">Confirm</Typography>
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </Modal>
    </Paper>
  );
};

const styles = theme => ({
  root: {
    width: '100%',
    overflow: 'auto',
  },
  compactRoot: {
    width: '100%',
    overflow: 'auto',
  },
  redAvatar: {
    color: red[500],
    backgroundColor: '#ffffff',
  },
  listItem: {
    paddingLeft: theme.spacing(2),
  },
  padding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemTextPadding: {
    paddingLeft: 0,
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
});

PreventionKitPanel.defaultProps = {
  compact: false,
  locationId: false,
};

PreventionKitPanel.propTypes = {
  compact: PropTypes.bool,
  locationId: PropTypes.string,
  fromTime: PropTypes.number.isRequired,
  toTime: PropTypes.number.isRequired,
};

export default compose(
  withStyles(styles, { withTheme: true }),
)(PreventionKitPanel);
