import React from 'react';
import {
  Create, SimpleForm, TextInput, required, minLength,
} from 'react-admin';

const redirect = () => '/references';

const validateName = [required(), minLength(2)];

export default props => (
  <Create {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="ref_category" validate={validateName} />
      <TextInput source="ref_value" validate={validateName} />
    </SimpleForm>
  </Create>
);
