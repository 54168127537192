import React from "react";
import { Box, Grid, useMediaQuery, withStyles } from "@material-ui/core";
import { compose } from "recompose";
import moment from "moment-timezone";
import { Title, useDataProvider } from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionPlanPanel,
  GuagePanel,
  SingleStatPanel,
  TimeSeriesPanel,
  TablePanel,
  NoTankPanel,
} from "./panels";
import { LocationFilter, TankFilter, TimeFilter } from "./filters";
import * as actions from "../store/actions";
import unitMap from "../utils/unitMap";
import { TimezoneContext } from "../contexts";

const TankDashboard = (props) => {
  const { classes } = props;
  const preferences = JSON.parse(localStorage.getItem("preferences"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const views = useSelector((state) => state.admin.ui.viewVersion);
  const selectedLocation = useSelector(
    (state) => state.locationData.selectedLocation
  );
  const selectedTank = useSelector((state) => state.tankData.selectedTank);
  const tanks = useSelector((state) => state.tankData.tanks);
  const selectedTimeRange = useSelector(
    (state) => state.timeRangeData.selectedTimeRange
  );
  const [lastData, setLastData] = React.useState();
  const [stream, setStream] = React.useState(false);
  const [avg, setAvg] = React.useState();
  const [days, setDays] = React.useState();
  const [empty, setEmpty] = React.useState();
  const { timezone } = React.useContext(TimezoneContext);
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    async function fetchLastData(tankId, fromTime, toTime) {
      const response = await dataProvider
        .getAll("tank/stream/last", {
          filter: { tankId, fromTime, toTime },
        })
        .then(({ data }) => data);
      setLastData(response);
    }

    async function fetchStream(tankId, fromTime, toTime) {
      const response = await dataProvider
        .getAll("tank/stream/all", {
          filter: {
            tankId,
            fromTime,
            toTime,
            fluidState: selectedTank.fluid_state,
            capacityGallons: selectedTank.capacity_gallons,
          },
        })
        .then(({ data }) => data);
      setStream(response.stream);
      setDays(response.days);
      setAvg(response.avg * unitMap[preferences.volume]);
      if (response.empty < 0) {
        setEmpty(0);
      } else {
        setEmpty(Math.round(response.empty));
      }
    }

    if (selectedLocation && !selectedTank) {
      const filteredTanks = tanks.filter(
        (tank) => tank.location_id === selectedLocation.id
      );
      dispatch({
        type: actions.SET_SELECTED_TANK,
        value: filteredTanks[0],
      });
    }

    if (selectedLocation && selectedTank && selectedTimeRange) {
      setLastData(undefined);
      setStream(undefined);
      setDays(undefined);
      setAvg(undefined);
      setEmpty(undefined);
      let start = moment(
        selectedTimeRange.start(timezone) * 1000
      ).toISOString();
      const end = moment(selectedTimeRange.end(timezone) * 1000).toISOString();
      const now = new Date(selectedTank.install_date);
      const tankInstalldateCompare = now.toISOString();

      if (tankInstalldateCompare > start) {
        start = tankInstalldateCompare;
      }

      fetchLastData(selectedTank.id, start, end);
      fetchStream(selectedTank.id, start, end);
    }
  }, [
    selectedTank,
    selectedLocation,
    selectedTimeRange,
    tanks,
    dispatch,
    dataProvider,
    views,
    timezone,
  ]);

  const onTankSelectedHandler = (tank) => {
    dispatch({
      type: actions.SET_SELECTED_TANK,
      value: tank,
    });
  };

  const onLocationSelectedHandler = (location) => {
    const filteredTanks = tanks.filter(
      (tank) => tank.location_id === location.id && tank.tank_status === "Yes"
    );
    dispatch({
      type: actions.SET_SELECTED_LOCATION,
      value: {
        id: location.id,
        desc: `${location.name}, ${location.address.name}`,
        timezone: location.timezone,
      },
    });
    dispatch({
      type: actions.SET_SELECTED_TANK,
      value: filteredTanks[0],
    });
  };

  return (
    <Box m={1}>
      <Title title="Tank" />
      <Grid
        className={classes.root}
        container
        justify="space-between"
        spacing={2}
      >
        <Grid item xs={isSmall && 12}>
          <LocationFilter onSelected={onLocationSelectedHandler} />
          <TankFilter onSelected={onTankSelectedHandler} />
        </Grid>
        <Grid item xs={isSmall && 12}>
          <TimeFilter />
        </Grid>
      </Grid>
      <br />

      {selectedTank === undefined && tanks.length !== 0 && <NoTankPanel />}

      {selectedTank !== undefined && (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={5}>
            <GuagePanel
              value={lastData && Math.round(lastData.percent)}
              fluidState={lastData && lastData.fluidState}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <Grid container spacing={3} direction="column" alignItems="stretch">
              <Grid item>
                <SingleStatPanel
                  stat={lastData && lastData.calcGal}
                  unit="volume"
                  desc={`Current ${preferences.volume}`}
                />
              </Grid>
              <Grid item>
                <SingleStatPanel
                  stat={lastData && lastData.calcInch}
                  rounded={false}
                  unit="length"
                  desc={`Current ${preferences.length}`}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5}>
            <ActionPlanPanel
              empty={empty}
              lastData={lastData}
              threshold={selectedTank && selectedTank.threshold}
              operator={selectedTank && selectedTank.operator}
              ruleName={selectedTank && selectedTank.rule_name}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <Grid container spacing={3} alignItems="stretch">
              <Grid item xs={12}>
                <Grid container spacing={3} alignItems="stretch">
                  <Grid item xs={6}>
                    <SingleStatPanel
                      stat={
                        lastData &&
                        Math.round(lastData.capacityGallons - lastData.calcGal)
                      }
                      unit="volume"
                      desc={
                        lastData && lastData.fluidState === "New"
                          ? `${preferences.volume} Used`
                          : `${preferences.volume} Remaining`
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SingleStatPanel
                      stat={empty}
                      desc={
                        lastData && lastData.fluidState === "New"
                          ? "Days Until Empty"
                          : "Days Until Full"
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TablePanel data={lastData} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={7}>
            <TimeSeriesPanel stream={stream} avg={avg} days={days} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const styles = (theme) => ({
  root: { marginTop: theme.spacing(1) },
});

export default compose(withStyles(styles, { withTheme: true }))(TankDashboard);
