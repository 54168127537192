import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import {
	List,
	SimpleList,
	Datagrid,
	TextField,
	EmailField,
	// FunctionField,
	Filter,
	TextInput,
	TopToolbar,
	CreateButton,
	required,
	email,
	minLength,
	useNotify,
} from 'react-admin'
import { ImportButton } from 'react-admin-import-csv'
import validator from '../../utils/validator'
import BulkActionButtons from '../components/BulkActionButtons'
import PostPagination from '../components/CustomPagination'
import PhoneField from '../components/PhoneField'
// import timestampFormat from '../components/DateFormat';

const CustomerFilter = (props) => (
	<Filter {...props}>
		<TextInput source='customer_no' />
		<TextInput source='name' />
		<TextInput source='phone' />
		<TextInput source='email' />
		<TextInput source='address.street' label='Street' />
		<TextInput source='address.city' label='City' />
		<TextInput source='address.state' label='State' />
		<TextInput source='address.postalCode' label='Zip' />
		<TextInput source='address.country' label='Country' />
	</Filter>
)

const validations = {
	name: [required('Name is required')],
	phone: [required('Phone is required')],
	email: [required('Email is required'), email('Email must be an email')],
	address: [required('Address is required')],
	contact_name: [minLength(2, 'Contact name should be at least 2 characters')],
	contact_email: [
		required('Contact Email is required'),
		email('Contact Email must be an email'),
	],
}

const ListActions = (props) => {
	const { className, basePath } = props
	const notify = useNotify()
	return (
		<TopToolbar className={className}>
			<CreateButton basePath={basePath} />
			<ImportButton
				{...props}
				logging
				disableImportNew
				disableImportOverwrite
				preCommitCallback={(action, values) => {
					console.log(action, values)
				}}
				postCommitCallback={(err) => {
					console.log('err', err)
				}}
				validateRow={(row) => {
					const errors = validator(validations, row)
					if (errors.length > 0) {
						console.log(errors)
						notify(errors.join(', '), 'error')
						throw new Error(errors.join(', '))
					}
					return Promise.resolve()
				}}
			/>
		</TopToolbar>
	)
}

export default ({ permissions, ...props }) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))
	return (
		<List
			{...props}
			bulkActionButtons={<BulkActionButtons permissions={permissions} />}
			filters={<CustomerFilter />}
			pagination={<PostPagination />}>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => record.name}
					secondaryText={(record) => record.address.name}
				/>
			) : (
				<Datagrid rowClick='edit'>
					<TextField source='customer_no' />
					<TextField source='name' />
					<PhoneField source='phone' />
					<EmailField source='email' />
					<TextField source='address.street' label='Street' />
					<TextField source='address.city' label='City' />
					<TextField source='address.state' label='State' />
					<TextField source='address.postalCode' label='Zip' />
					<TextField source='address.country' label='Country' />
				</Datagrid>
			)}
		</List>
	)
}
