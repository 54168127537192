import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  DateTimeInput,
  required,
  minValue,
  maxValue,
  number,
  RadioButtonGroupInput,
  // Toolbar,
  // SaveButton,
  // DeleteButton,
} from 'react-admin';

const redirect = () => '/rules';

const validateRequired = required();
const validateMin = minValue(0);
const validateMax = maxValue(100);
const validateNumber = number();

export default props => (
  <Edit title="Edit Rule" {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput disabled source="id" />
      <TextInput source="rule_name" validate={[validateRequired]} />
      <RadioButtonGroupInput
        source="operator"
        choices={[
          { id: '<=', name: '<=' },
          { id: '>=', name: '>=' },
          { id: '<', name: '<' },
          { id: '>', name: '>' },
        ]}
        validate={[validateRequired]}
      />
      <TextInput
        source="threshold"
        type="number"
        validate={[validateMin, validateMax, validateRequired, validateNumber]}
      />
      <BooleanInput source="is_active" defaultValue />
      <DateTimeInput source="created_at" disabled />
      <DateTimeInput source="updated_at" disabled />
    </SimpleForm>
  </Edit>
);
