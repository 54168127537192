import * as React from "react";
import { Checkbox, TextField, Autocomplete } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IconEvent from "@material-ui/icons/List";
import { Button } from "react-admin";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const columnList = [
  {
    title: "Tank Manufacturer",
    source: "tank_manufacturer_id",
    reference: "tank-manufacturers",
    allow: "allowEmpty",
    link: true,
    textSource: "name",
  },
  {
    title: "Retailer",
    source: "retailer_id",
    reference: "retailers",
    allow: "allowEmpty",
    link: true,
    textSource: "name",
  },
  {
    title: "Distributor",
    source: "distributor_id",
    reference: "distributors",
    allow: "allowEmpty",
    link: true,
    textSource: "name",
  },
  {
    title: "Location No",
    source: "",
    reference: "",
    allow: "",
    textSource: "location_no",
    link: "",
  },
  {
    title: "Fluid type",
    source: "fluid_type_ref_id",
    reference: "references",
    allow: "allowEmpty",
    link: false,
    textSource: "ref_value",
  },
  {
    title: "Fluid manufacturer",
    source: "manufacturer_id",
    reference: "manufacturers",
    allow: "allowEmpty",
    link: true,
    textSource: "name",
  },
  {
    title: "Fluid brand",
    source: "",
    reference: "",
    allow: "",
    textSource: "fluid_brand",
    link: "",
  },
  {
    title: "Fluid segment",
    source: "fluid_segment_ref_id",
    reference: "references",
    allow: "allowEmpty",
    textSource: "ref_value",
    link: false,
  },
  {
    title: "Capacity (gal.)",
    source: "",
    reference: "",
    allow: "",
    textSource: "capacity_gallons",
    link: "",
  },
  {
    title: "Depth (in.)",
    source: "",
    reference: "",
    allow: "",
    textSource: "depth_inches",
    link: "",
  },
  {
    title: "Serial No",
    source: "",
    reference: "",
    allow: "",
    textSource: "serial_no",
    link: "",
  },
  {
    title: "Current Calibration",
    source: "",
    reference: "",
    allow: "",
    textSource: "curr_calibration",
    link: "",
  },
];

export default function CheckboxesTags(props) {
  const selectedTanksColumns =
    JSON.parse(localStorage.getItem("tankColumns")) || [];
  const getDefaultValue = selectedTanksColumns.map((col) => col);

  let initialState = false;
  if (selectedTanksColumns.length !== 0) {
    initialState = true;
  }
  const [showBox, setShowBox] = React.useState(initialState);

  const getSelectedCheckbox = (option) => {
    if (selectedTanksColumns.some((col) => col.title === option.title)) {
      return true;
    }
    return false;
  };

  const onTagsChange = (event, dropValues) => {
    props.setColumns(dropValues);
    localStorage.setItem("tankColumns", JSON.stringify(dropValues));
  };

  return (
    <div>
      {!showBox && (
        <Button
          onClick={() => {
            setShowBox(true);
          }}
          label="Select Columns"
        >
          <IconEvent />
        </Button>
      )}
      {showBox && (
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={columnList}
          defaultValue={getDefaultValue}
          disableCloseOnSelect
          getOptionLabel={(option) => option?.title}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={getSelectedCheckbox(option)}
              />
              {option?.title}
            </li>
          )}
          style={{
            width: "auto",
            minWidth: "200px",
            height: "auto",
          }}
          onChange={onTagsChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Columns"
              style={{ height: "auto" }}
            />
          )}
        />
      )}
    </div>
  );
}
