import React from 'react';

const NoTankPanel = () => (
  <div style={{ textAlign: 'center' }}>
    <br />
    <h4>
      No tanks found
    </h4>
    <p>
      Try selecting an alternate location
    </p>
  </div>
);

export default NoTankPanel;
