import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import {
	List,
	SimpleList,
	Datagrid,
	TextField,
	FunctionField,
	EmailField,
	ReferenceField,
	ReferenceArrayField,
	SingleFieldList,
	ChipField,
	Filter,
	TextInput,
	ReferenceInput,
	SelectInput,
} from 'react-admin'
import BulkActionButtons from '../components/BulkActionButtons'
import PostPagination from '../components/CustomPagination'
import timestampFormat from '../components/DateFormat'

const UserFilter = (props) => (
	<Filter {...props}>
		<TextInput source='name' />
		<TextInput source='email' />
		<ReferenceInput
			label='Role'
			source='role_ref_id'
			reference='references'
			sort={{ field: 'ref_value', order: 'ASC' }}
			filter={{ ref_category: 'ROLE' }}>
			<SelectInput optionText='ref_value' />
		</ReferenceInput>
	</Filter>
)

export default ({ permissions, ...props }) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))
	return (
		<List
			{...props}
			exporter={false}
			bulkActionButtons={<BulkActionButtons permissions={permissions} />}
			filters={<UserFilter />}
			pagination={<PostPagination />}>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => record.name}
					secondaryText={(record) => (
						<ReferenceField
							label='Role'
							source='role_ref_id'
							reference='references'
							basePath='references'
							record={record}
							link={false}>
							<TextField source='ref_value' />
						</ReferenceField>
					)}
					tertiaryText={(record) =>
						new Date(record.updated_at).toLocaleDateString()
					}
				/>
			) : (
				<Datagrid rowClick='edit'>
					<EmailField source='email' />
					<TextField source='name' />
					<ReferenceField
						label='Role'
						source='role_ref_id'
						reference='references'
						link={false}>
						<TextField source='ref_value' />
					</ReferenceField>
					<ReferenceArrayField
						label='Manufacturers'
						reference='manufacturers'
						source='manufacturers'
						allowEmpty>
						<SingleFieldList>
							<ChipField source='name' />
						</SingleFieldList>
					</ReferenceArrayField>
					<ReferenceArrayField
						label='Distributors'
						reference='distributors'
						source='distributors'
						allowEmpty>
						<SingleFieldList>
							<ChipField source='name' />
						</SingleFieldList>
					</ReferenceArrayField>
					<ReferenceArrayField
						label='Locations'
						reference='locations'
						source='locations'
						allowEmpty>
						<SingleFieldList>
							<ChipField source='name' />
						</SingleFieldList>
					</ReferenceArrayField>
					<ReferenceArrayField
						label='Retailers'
						reference='retailers'
						source='retailers'
						allowEmpty>
						<SingleFieldList>
							<ChipField source='name' />
						</SingleFieldList>
					</ReferenceArrayField>
					<ReferenceField
						source='volume_ref_id'
						label='Volume unit'
						reference='references'
						link={false}>
						<TextField source='ref_value' />
					</ReferenceField>
					<ReferenceField
						source='length_ref_id'
						label='Length unit'
						reference='references'
						link={false}>
						<TextField source='ref_value' />
					</ReferenceField>
					<ReferenceField
						source='date_ref_id'
						label='Date format'
						reference='references'
						link={false}>
						<TextField source='ref_value' />
					</ReferenceField>
					<ReferenceField
						source='time_ref_id'
						label='Time format'
						reference='references'
						link={false}>
						<TextField source='ref_value' />
					</ReferenceField>
					<FunctionField
						source='created_at'
						render={(record) => timestampFormat(record.created_at)}
					/>
					<FunctionField
						source='updated_at'
						render={(record) => timestampFormat(record.updated_at)}
					/>
				</Datagrid>
			)}
		</List>
	)
}
