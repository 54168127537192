import RetailerIcon from '@material-ui/icons/Store';
import RetailerList from './RetailerList';
import RetailerEdit from './RetailerEdit';
import RetailerCreate from './RetailerCreate';

export default {
  list: RetailerList,
  edit: RetailerEdit,
  create: RetailerCreate,
  icon: RetailerIcon,
};
