import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  DateTimeInput,
  required,
  minLength,
  email,
} from 'react-admin';
import AddressInput from '../components/AddressInput';
import PhoneInput from '../components/PhoneInput';

const redirect = () => '/distributors';

const validateRequired = required();
const validateEmail = email();
const validateName = minLength(2);
const validatePhone = minLength(10);

export default props => (
  <Edit title="Edit Distributor" {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput disabled source="distributor_no" label="Service provider no" />
      <TextInput source="name" validate={[validateRequired, validateName]} />
      <PhoneInput
        source="phone"
        label="Phone"
        validate={[validateRequired, validatePhone]}
      />
      <TextInput source="email" validate={[validateRequired, validateEmail]} />
      <AddressInput source="address" validate={validateRequired} />
      <PhoneInput source="emergency_no" label="Emergency No" validate={[validatePhone]} />
      <TextInput source="contact_name" validate={[validateName]} />
      <PhoneInput source="contact_no" label="Contact No" validate={[validatePhone]} />
      <TextInput source="contact_email" validate={[validateEmail]} />
      <BooleanInput source="is_active" defaultValue />
      <DateTimeInput source="created_at" disabled />
      <DateTimeInput source="updated_at" disabled />
    </SimpleForm>
  </Edit>
);
