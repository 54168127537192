import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  withStyles,
  useTheme,
} from "@material-ui/core";
import Skeleton from "react-loading-skeleton";

import LoadingIndicator from "../../components/LoadingIndicator";
import NoDataToShow from "../../components/NoDataToShow";
import unitMap from "../../utils/unitMap";

const TablePanel = (props) => {
  const { data, classes } = props;
  const theme = useTheme();

  const preferences = JSON.parse(localStorage.getItem("preferences"));

  if (data === undefined) {
    return <Skeleton height={theme.spacing(26)} />;
  }

  if (!data) {
    return (
      <Paper className={classes.loadingContainer}>
        {data === undefined ? <LoadingIndicator /> : <NoDataToShow />}
      </Paper>
    );
  }

  const rows = [
    {
      "Service Contact": (data && data.distributorName) || "N/A",
      "Motor Oil": (data && data.fluidBrand) || "N/A",
      Capacity:
        (data &&
          `${preferences.length}: ${Math.round(
            data.depthInches * unitMap[preferences.length]
          )}`) ||
        "N/A",
    },
    {
      "Service Contact": (data && data.distributorContactNo) || "N/A",
      "Motor Oil": (data && data.fluidSegment) || "N/A",
      Capacity:
        (data &&
          `${preferences.volume}: ${Math.round(
            data.capacityGallons * unitMap[preferences.volume]
          )}`) ||
        "N/A",
    },
    {
      "Service Contact": (data && data.distributorContactEmail) || "N/A",
      "Motor Oil": (data && data.fluidProductType) || "N/A",
      Capacity: (data && `Quarts: ${Math.round(data.capacityQuarts)}`) || "N/A",
    },
  ];

  const keys = Object.keys(rows[0]).map((i) => i.toUpperCase());
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {keys.map((key) => (
              <TableCell key={key} className={classes.head}>
                {key}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((each, index) => (
            <TableRow key={index}>
              {Object.keys(each).map((key) => (
                <TableCell key={key}>{each[key]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

const styles = () => ({
  root: { width: "100%", overflowX: "auto" },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  head: { fontWeight: 900 },
});

export default withStyles(styles)(TablePanel);
