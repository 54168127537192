/* eslint-disable camelcase */
/* eslint-disable no-alert */
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Title, useDataProvider } from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {
  FormControl, Grid, InputLabel, Select,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  close: {
    padding: theme.spacing(1),
  },
  buttonBackground: {
    backgroundColor: '#F5F5F5',
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  card: {
    minWidth: 275,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});


const ChangePassword = (props) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [old_password, setOldPassword] = React.useState(false);
  const [new_password, setNewPassword] = React.useState(false);
  const [confirm_password, setConfirmPassword] = React.useState(false);

  const onChange = (e) => {
    if (e.target.name === 'old_password') {
      setOldPassword(e.target.value);
    }
    if (e.target.name === 'new_password') {
      setNewPassword(e.target.value);
    }
    if (e.target.name === 'confirm_password') {
      setConfirmPassword(e.target.value);
    }
  };

  const { classes } = props;
  const dataProvider = useDataProvider();
  const id = localStorage.getItem('id');
  const history = useHistory();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (new_password === confirm_password) {
      setSubmitting(true);
      try {
        await dataProvider.update('users', { id, type: 'change-password', data: { old_password, new_password } });
        setSubmitting(false);
        alert('Password Changed');
        history.push('/');
      } catch {
        setSubmitting(false);
        alert('Incorrect Old Password');
      }
    } else {
      alert('Passwords do not match');
    }
  };

  return (
    <div>
      <Title title="Change Password" />
      <Card className={classes.card}>
        <form onSubmit={onSubmit}>
          <CardContent>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="old_password"
                  type="password"
                  label="Old Password"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="new_password"
                  type="password"
                  label="New Password"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="confirm_password"
                  type="password"
                  label="Confirm Password"
                  onChange={onChange}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.buttonBackground}>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                className={classes.button}
                type="submit"
                color="primary"
                disabled={submitting}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </div>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
}));

const ReferenceSelectInput = (props) => {
  const classes = useStyles();
  const {
    label, name, defaultValue, value,
  } = props;
  const dataProvider = useDataProvider();


  return (
    <FormControl className={classes.textField}>
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        defaultValue={defaultValue}
        value={value}
      />
    </FormControl>
  );
};

ReferenceSelectInput.defaultProps = {
  defaultValue: undefined,
  value: undefined,
};

ReferenceSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
};

ChangePassword.propTypes = {

};

export default compose(withStyles(styles))(ChangePassword);
