import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  required,
  minLength,
  email,
} from 'react-admin';
import moment from 'moment-timezone';
import AddressInput from '../components/AddressInput';
import PhoneInput from '../components/PhoneInput';

const redirect = () => '/locations';

const validateRequired = required();
const validateEmail = email();
const validateName = minLength(2);
const validatePhone = minLength(10);

const timezones = moment.tz.zonesForCountry('US', true);
timezones.sort((a, b) => a.offset - b.offset);

export default props => (
  <Create {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="name" validate={[validateRequired, validateName]} />
      <PhoneInput
        source="phone"
        label="Phone"
        validate={[validateRequired, validatePhone]}
      />
      <TextInput source="email" validate={[validateRequired, validateEmail]} />

      <ReferenceInput
        label="Customer"
        source="customer_id"
        reference="customers"
        sort={{ field: 'name', order: 'ASC' }}
        // filter={{ ref_category: 'FLUID_STATE' }}
        validate={[validateRequired]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>

      <AddressInput source="address" validate={validateRequired} />
      <SelectInput
        style={{ width: 400 }}
        source="timezone"
        validate={validateRequired}
        choices={timezones.map((tz) => {
          const duration = moment.duration(tz.offset, 'minutes').abs();
          const hours = duration.hours().toString().padStart(2, '0');
          const minutes = duration.minutes().toString().padStart(2, '0');
          return {
            id: tz.name,
            name: `(UTC${tz.offset > 0 ? '-' : '+'}${hours}:${minutes}) ${tz.name}: ${moment.tz(tz.name).zoneAbbr()}`,
          };
        })}
      />
      <TextInput source="mobile" />
      <BooleanInput source="is_active" defaultValue />
    </SimpleForm>
  </Create>
);
