import { fetchUtils } from 'react-admin'
import qs from 'qs'

const httpClient = fetchUtils.fetchJson
const apiUrl = process.env.REACT_APP_API_URL
const CREATE_MANY = 'CREATE_MANY'
const GET_ALL = 'GET_ALL'
const GET_LIST = 'GET_LIST'
const GET_ONE = 'GET_ONE'
const GET_MANY = 'GET_MANY'
const GET_MANY_REFERENCE = 'GET_MANY_REFERENCE'
const CREATE = 'CREATE'
const UPDATE = 'UPDATE'
const UPDATE_MANY = 'UPDATE_MANY'
const DELETE = 'DELETE'
const DELETE_MANY = 'DELETE_MANY'

const convertDataRequestToHTTP = (type, resource, params) => {
	let url = ''
	const options = {}
	switch (type) {
		case GET_LIST: {
			const { page, perPage } = params.pagination
			const { field, order } = params.sort
			const query = {
				...params.filter,
				orderBy: field,
				order,
				startAfter: (page - 1) * perPage,
				limit: perPage,
			}
			url = `${apiUrl}/${resource}?${qs.stringify(query)}`
			break
		}
		case GET_ONE:
			url = `${apiUrl}/${resource}/${params.id}`
			break
		case GET_MANY_REFERENCE: {
			const { page, perPage } = params.pagination
			const { field, order } = params.sort
			const query = {
				...params.filter,
				[params.target]: params.id,
				orderBy: field,
				order,
				startAfter: (page - 1) * perPage,
				limit: perPage,
			}
			url = `${apiUrl}/${resource}?${qs.stringify(query)}`
			break
		}
		case UPDATE:
			if (params.type != null) {
				url = `${apiUrl}/${resource}/${params.id}/${params.type}`
			} else url = `${apiUrl}/${resource}/${params.id}`
			options.method = 'PUT'
			options.body = JSON.stringify(params.data)
			break
		case CREATE_MANY:
			url = `${apiUrl}/${resource}`
			options.method = 'POST'
			options.body = JSON.stringify(params.data)
			break
		case CREATE:
			url = `${apiUrl}/${resource}`
			options.method = 'POST'
			options.body = JSON.stringify(params.data)
			break

		case DELETE:
			url = `${apiUrl}/${resource}/${params.id}`
			options.method = 'DELETE'
			break
		case GET_MANY: {
			const query = {
				filter: JSON.stringify({ id: params.ids }),
			}
			url = `${apiUrl}/${resource}?${qs.stringify(query)}`
			break
		}
		case GET_ALL: {
			const query = { ...params.filter }
			url = `${apiUrl}/${resource}?${qs.stringify(query)}`
			break
		}
		default:
			throw new Error(`Unsupported fetch action type ${type}`)
	}

	return { url, options }
}

const convertHTTPResponse = (response, type, resource, params) => {
	const { headers, json } = response
	switch (type) {
		case GET_LIST:
		case GET_MANY_REFERENCE:
			if (!headers.has('x-total-count')) {
				throw new Error(
					'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
				)
			}
			return {
				data: json,
				total: parseInt(
					headers
						.get('x-total-count')
						.split('/')
						.pop(),
					10
				),
			}
		case CREATE:
			return { data: { ...params.data, id: json.id } }
		default:
			return { data: json }
	}
}

const handler = async (type, resource, params) => {
	// json-server doesn't handle filters on UPDATE route,
	// so we fallback to calling UPDATE n times instead

	const token = localStorage.getItem('token')
	const headersObj = {
		Accept: 'application/json',
		Authorization: `Bearer ${token}`,
	}
	const headers = new Headers(headersObj)
	if (type === UPDATE_MANY) {
		return Promise.all(
			params.ids.map((id) =>
				httpClient(`${apiUrl}/${resource}/${id}`, {
					headers,
					method: 'PUT',
					body: JSON.stringify(params.data),
				})
			)
		).then((responses) => ({
			data: responses.map((response) => response.json),
		}))
	}
	// json-server doesn't handle filters on DELETE route,
	// so we fallback to calling DELETE n times instead
	if (type === DELETE_MANY) {
		return Promise.all(
			params.ids.map((id) =>
				httpClient(`${apiUrl}/${resource}/${id}`, {
					headers,
					method: 'DELETE',
				})
			)
		).then((responses) => ({
			data: responses.map((response) => response.json),
		}))
	}
	const { url, options } = convertDataRequestToHTTP(type, resource, params)
	return httpClient(url, {
		...options,
		headers,
	})
		.then((response) => convertHTTPResponse(response, type, resource, params))
		.catch((err) => {
			const {
				body: { error },
			} = err
			// throw new Error(error);
			console.log(error, 'error in data provider')
		})
}

const dataProvider = {
	getList: (resource, params) => handler(GET_LIST, resource, params),
	getOne: (resource, params) => handler(GET_ONE, resource, params),
	getMany: (resource, params) => handler(GET_MANY, resource, params),
	getManyReference: (resource, params) =>
		handler(GET_MANY_REFERENCE, resource, params),
	create: (resource, params) => handler(CREATE, resource, params),
	update: (resource, params) => handler(UPDATE, resource, params),
	updateMany: (resource, params) => handler(UPDATE_MANY, resource, params),
	delete: (resource, params) => handler(DELETE, resource, params),
	deleteMany: (resource, params) => handler(DELETE_MANY, resource, params),
	createMany: (resource, params) => handler(CREATE_MANY, resource, params),
	getAll: (resource, params) => handler(GET_ALL, resource, params),
}

export default dataProvider
