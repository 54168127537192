import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import {
	List,
	SimpleList,
	Datagrid,
	TextField,
	Filter,
	TextInput,
} from 'react-admin'
import BulkActionButtons from '../components/BulkActionButtons'
import PostPagination from '../components/CustomPagination'

const RuleFilter = (props) => (
	<Filter {...props}>
		<TextInput source='rule_name' />
	</Filter>
)

export default ({ permissions, ...props }) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))
	return (
		<List
			{...props}
			exporter={false}
			bulkActionButtons={<BulkActionButtons permissions={permissions} />}
			filters={<RuleFilter />}
			pagination={<PostPagination />}>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => record.rule_name}
					tertiaryText={(record) => `${record.operator} ${record.threshold}`}
				/>
			) : (
				<Datagrid rowClick='edit'>
					<TextField source='rule_name' />
					<TextField source='operator' />
					<TextField source='threshold' />
				</Datagrid>
			)}
		</List>
	)
}
